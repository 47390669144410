import React from "react";
import { useOverlay } from "./hooks/useOverlay";
export const TableOverlaySelect = () => {
  const { overlayStyles } = useOverlay();
  const { width, height, ...rest } = overlayStyles;
  return null; // MELHORAR OVERLAY OU DESCONSIDERAR

  return (
    <div
      style={{
        position: "absolute",
        border: "3px solid blue",
        pointerEvents: "none",
        transition: "0s",
        zIndex: 1,
        width,
        height,
        ...rest,
      }}
    ></div>
  );
};
