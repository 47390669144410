import React, { useState } from "react";
import ColorPickerComponent from "../../../../components/color-picker/color-picker";
import { FontPicker } from "../../../../components";
import { Wrapper } from "./table-properties.style";
import { SkillGroup } from "../../../../components/skills/SkillGroup";
import PreferenceRequiredInteraction from "../../../../components/preference-required-interaction/preference-required-interaction";

export const TableProperties = ({ content, onContentChange, labels }) => {
  const { background_color, headers_background, headers_color, font_family, font_color, font_size } = content;
  const [config, setConfig] = useState(content);

  const onChangeTable = (value, property) => {
    onContentChange({
      ...content,
      [property]: value,
    });
  };

  const buildChangeHandler =
    (name, getter = (e) => e.target.value) =>
    (e) =>
      onContentChange({ ...content, [name]: getter(e) || content[name] });

  const renderSettings = () => (
    <>
      <SkillGroup
        selectedProficiency={config.proficiency}
        selectedSkill={config.skill}
        onChangeProficiency={(proficiency) => {
          const newContent = {
            ...config,
            proficiency,
          };
          setConfig(newContent);
          onContentChange(newContent);
        }}
        onChangeSkill={(skill) => {
          const newContent = {
            ...config,
            skill,
          };
          setConfig(newContent);
          onContentChange(newContent);
        }}
      />

      <span className="rdp-composer-element-edit-label">
        {labels.elements.settings.element_interactive_video.settings_label_margin_top}
      </span>
      <input
        type="number"
        className="rdp-composer-element-edit-input px"
        onChange={buildChangeHandler("padding_top")}
        defaultValue={content.padding_top}
      />

      <span className="rdp-composer-element-edit-label">
        {labels.elements.settings.element_interactive_video.settings_label_margin_bottom}
      </span>
      <input
        type="number"
        className="rdp-composer-element-edit-input px"
        onChange={buildChangeHandler("padding_bottom")}
        defaultValue={content.padding_bottom}
      />

      <PreferenceRequiredInteraction
        title={labels.elements.settings.preference_required_interaction.title}
        label={labels.elements.settings.preference_required_interaction.label}
        labels={labels}
        content={content}
        onContentChange={onContentChange}
      />
    </>
  );

  function renderFont() {
    if (font_family) {
      return (
        <FontPicker
          font={font_family || "Lato"}
          onFontChange={(font) => {
            onChangeTable(font, "font_family");
          }}
        />
      );
    } else {
      return (
        <FontPicker
          hasFont={false}
          onFontChange={(font) => {
            onChangeTable(font, "font_family");
          }}
          noFontLabel={"Nenhuma fonte"}
        />
      );
    }
  }

  return (
    <Wrapper className="rdp-element-table-properties">
      <ul className="rdp-composer-element-edit-tabs">
        <li className={["rdp-composer-element-edit-tabs-item", "active"].join(" ")}>Configurações</li>
      </ul>

      <div className="rdp-composer-element-edit-body">
        <span className="rdp-composer-element-edit-label">
          {labels.elements.settings.element_interactive_video.settings_label_content_title}:
        </span>
        <input
          type="text"
          className="rdp-composer-element-edit-input"
          onChange={buildChangeHandler("content_title")}
          defaultValue={content.content_title}
        />

        <span className="rdp-composer-element-edit-label">Cor da fonte do cabeçalho</span>
        <ColorPickerComponent
          labels={labels}
          color={headers_color || "#ffffff"}
          onChange={(newColor) => {
            onChangeTable(newColor, "headers_color");
          }}
        />
        <span className="rdp-composer-element-edit-label">Cor de fundo do cabeçalho</span>
        <ColorPickerComponent
          labels={labels}
          color={headers_background || "#00643e"}
          onChange={(newColor) => {
            onChangeTable(newColor, "headers_background");
          }}
        />
        <span className="rdp-composer-element-edit-label">Cor do fundo</span>
        <ColorPickerComponent
          labels={labels}
          color={background_color || "#ffffff"}
          onChange={(newColor) => {
            onChangeTable(newColor, "background_color");
          }}
        />

        <span className="rdp-composer-element-edit-label">Fonte do texto</span>
        {renderFont()}

        {renderSettings()}
      </div>
    </Wrapper>
  );
};
