import { useEffect, useRef, useState } from "react";

export const useDebouncedChange = (initialValue, delay = 300, callback) => {
  const [value, setValue] = useState(initialValue);
  const debounceTimeout = useRef(null);
  const isFirstMount = useRef(true);

  const handleChange = (newValue) => {
    setValue(newValue);
    if (isFirstMount.current) {
      isFirstMount.current = false;
      return;
    }

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      callback(newValue);
    }, delay);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (initialValue !== value) setValue(initialValue);
  }, [initialValue]);

  return [value, handleChange];
};
