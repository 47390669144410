import { useState } from "react";
import usePopover from "../../../components/custom-popover/use-popover";
import { useTableContext } from "../context/table-context";

const initialSelectedCell = { row: null, column: null };

export const useTableMouseHandlers = () => {
  const { tableData: table } = useTableContext();
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCell, setSelectedCell] = useState(initialSelectedCell);
  const [selectedCellColumn, setSelectedCellColumn] = useState(null);
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoverCell, setHoverCell] = useState(null);
  const [showOptionsCell, setShowOptionsCell] = useState(null);
  const popover = usePopover();
  const popoverAlignHorizontal = usePopover();
  const popoverAlignVertical = usePopover();
  const popoverAlignHorizontalColumn = usePopover();
  const popoverAlignVerticalColumn = usePopover();
  const popoverColumn = usePopover();
  const popoverCell = usePopover();
  const popoverColumnColor = usePopover();
  const selectedRowIndex = table.rows.findIndex((row) => row.id === selectedRow || selectedCell.row === row.id);
  const selectedColumnIndex = table.columns.findIndex((column) => column.id === selectedColumn);
  const activeColumn = table.columns.find((item) => item.id === selectedColumn);
  
  const handleClearSelecteds = () => {
    setSelectedCellColumn(null);
    setSelectedRow(null);
    setSelectedColumn(null);
    setSelectedCell(initialSelectedCell);
  };

  const handleColumnCellClick = (col) => {
    setSelectedCellColumn(col.id);
    setSelectedRow(null);
    setSelectedColumn(null);
    setSelectedCell(initialSelectedCell);
  };

  const handleCellClick = (col, row) => {
    setSelectedCell({ column: col.id, row: row.id });
    setSelectedCellColumn(null);
    setSelectedRow(null);
    setSelectedColumn(null);
  };

  const handleColumnClick = (columnId, e) => {
    setSelectedColumn(columnId);
    setSelectedRow(null);
    setSelectedCell(initialSelectedCell);
    setSelectedCellColumn(null);

    popoverColumn.onOpen(e);
  };

  const handleRowClick = (rowId, e) => {
    setSelectedRow(rowId);
    setSelectedColumn(null);
    setSelectedCellColumn(null);
    setSelectedCell(initialSelectedCell);

    // popover.onOpen(e);
  };

  const handleMouseEnterColumn = (columnId) => {
    setHoveredColumn(columnId);
  };

  const handleMouseLeaveColumn = () => {
    setHoveredColumn(null);
  };

  const handleMouseEnterRow = (rowId) => {
    setHoveredRow(rowId);
  };

  const handleMouseLeaveRow = () => {
    setHoveredRow(null);
  };

  const handleMouseOverCell = (col) => {
    const data = table;
    if (data.columns[data.columns.length - 1].id === col.id) {
      setHoverCell(true);
    }
  };

  const handleMouseLeaveCell = () => {
    if (hoverCell) {
      setHoverCell(null);
    }
  };

  const canRowChangePosition = (position = "down") => {
    if (!selectedRowIndex && position === "up") return false;
    if (selectedRowIndex === table.rows.length - 1 && position === "down") return false;

    return true;
  };

  const canColumnChangePosition = (position = "right") => {
    if (!selectedColumnIndex && position === "left") return false;
    if (selectedColumnIndex === table.columns.length - 1 && position === "right") return false;

    return true;
  };

  const handleShowOptionsCell = (value) => {
    setShowOptionsCell(value);
  };

  const cellSelected = (col, row) => {
    return selectedCell && selectedCell.row === row.id && selectedCell.column === col.id;
  };

  const cellSelectedPerParents = (col, row) => {
    const parent = selectedColumn === col.id || selectedRow === row.id;
    return parent || cellSelected(col, row);
  };

  const selectRow = (rows) => {
    if (selectedCell.row) {
      handleRowClick(selectedCell.row)
    }
  };

  return {
    handleColumnClick,
    handleColumnCellClick,
    handleMouseEnterColumn,
    handleMouseEnterRow,
    handleMouseOverCell,
    handleMouseLeaveCell,
    handleMouseLeaveRow,
    handleMouseLeaveColumn,
    handleRowClick,
    handleCellClick,
    handleShowOptionsCell,
    handleClearSelecteds,
    cellSelected,
    cellSelectedPerParents,
    showOptionsCell,
    selectRow,
    selectedCellColumn,
    selectedColumn,
    selectedCell,
    selectedRow,
    selectedRowIndex,
    selectedColumnIndex,
    hoveredColumn,
    hoveredRow,
    hoverCell,
    popover,
    popoverColumn,
    canColumnChangePosition,
    canRowChangePosition,
    popoverCell,
    activeColumn,
    popoverColumnColor,
    popoverAlignHorizontal,
    popoverAlignVertical,
    popoverAlignHorizontalColumn,
    popoverAlignVerticalColumn,
  };
};
