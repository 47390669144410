import styled from "styled-components";

export const ElementButton1 = styled.div`
  background-color: ${props => props.backgroundColor || "#fff"};
  padding-top: ${props => props.paddingTop || "0"}px;
  padding-bottom: ${props => props.paddingBottom || "0"}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  /*region element max-width*/
  /*SM*/
  &.max-w-sm .element-button-1__button-list  {
    max-width: 760px;
  }
  /*MD*/
  &.max-w-md .element-button-1__button-list  {
    max-width: 1160px;
  }
  /*LG*/
  &.max-w-lg .element-button-1__button-list  {
    max-width: 1600px;
  }
  /*FULL*/
  &.max-w-full .element-button-1__button-list  {
    max-width: calc(100% - 60px);
  }
  /*endregion*/
`;

export const ButtonList = styled.ul`
  flex: 3;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: auto;
`;

export const ButtonItem = styled.li`
  margin: 30px 0;
  width: 100%;

  @media screen and (max-width: 649px) {
    padding: 0 20px;
  }
`;

export const ButtonLabel = styled.label`
  display: inline-block;
  width: calc(100% - 300px);
  vertical-align: middle;

  @media screen and (max-width: 649px) {
    display: block;
    width: 100%;
  }

  &.ql-editor {
    font: super !important;

    h1, h2, h3, h4, h5, h6 {
      font-weight: bold;
      margin: 1em 0 0.5em;
    }

    p {
      margin: 0 0 1em;
    }

    strong, b {
      font-weight: bold !important;
    }

    em, i {
      font-style: italic !important;
    }

    u {
      text-decoration: underline !important;
    }

    ol, ul {
      padding-left: 2em !important;
      margin: 0 0 1em;
    }

    ol {
      list-style-type: decimal !important;
    }

    ul {
      list-style-type: disc !important;
    }

    li {
      display: list-item !important;
    }
  }
`;

export const ButtonLink = styled.a`
  display: inline-block;
  width: 250px;
  vertical-align: middle;
  margin-left: 50px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: ${props => props.primaryColor || "#00643e"};
  color: white;
  transition: all 0.3s ease-out;
  border: 0;
  padding: 18px 30px;
  font-weight: bold;
  border-radius: 30px;
  font-size: 1em;
  pointer-events: auto;
  text-decoration: none;
  text-align: center;

  &:hover {
    filter: brightness(1.1);
  }

  &:focus {
    outline: 2px solid ${props => props.primaryColor || "#00643e"} !important;
    outline-offset: 2px;
  }

  &:active {
    background-color: ${props => props.primaryColor || "#00643e"};
    filter: brightness(1.2);
  }

  @media screen and (max-width: 649px) {
    display: block;
    width: 100%;
    margin-left: 0px;
    margin-top: 30px;
  }
`;