import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: ${props => props.paddingBottom || '1rem'};
  padding-top: ${props => props.paddingTop || '3rem'};
`;

export const TableContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto 50px;
  position: relative;
`;

export const TableScrolled = styled.div`
  overflow-x: auto;
  overflow-y: hidden; 
  scrollbar-width: thin;
`
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  position: relative;
`;

export const TableHeader = styled.thead``;

export const TableBody = styled.tbody``;

export const TableRowAction = styled.div`
  position: absolute;
  bottom: -35px;
  width: 100%;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;

export const TableColumnAction = styled.td`
  padding: 0px;
  bottom: 0;
  top: 0;
  border: none;
  width: 0px;
  padding: 0px;
  position: absolute;
  right: -6px;
  height: 100%;
  opacity: 0;

  div {
    height: 100%;
    width: 25px;
  }

  &:hover {
    opacity: 1;
  }
`;

export const TableCellFakeQuill = styled.div`
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

export const TableCellContainer = styled.div`
  position: relative;
`;

export const TableCellAction = styled.td`
  border: none;
  width: 0;
  padding: 0;
`;

export const TableRow = styled.tr`
  strong {
    font-weight: bold;
  }

  &:hover .hover-button-row {
    visibility: visible;
    opacity: 1;
  }

  &:nth-last-child(2):hover ~ ${TableRowAction} {
    opacity: 1;
  }
`;

export const HoverButtonRow = styled.button`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: -36px;
  transform: translateY(-50%);
  padding: 4px 8px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 1.3rem;
  background: none;
  color: #878787;
  &:hover {
    opacity: 1;
    visibility: visible;
  }
`;

export const HoverButtonColumn = styled(HoverButtonRow)`
  transform: auto;
  left: auto;
  right: 0;
  color: currentColor;

  svg {
    pointer-events: none;
  }
  &:hover {
    visibility: visible;
    opacity: 1;
  }
`;

export const HoverButtonCell = styled(HoverButtonColumn)`
  z-index: 1;
`;

const getColumnFontColor = (props) => {
  const { col } = props;
  if (col.column_font_color) return col.column_font_color;
  if (col.font_color) return col.font_color;
  return "#fff";
};

export const TableCell = styled.td`
  border: 1px solid #ddd;
  text-align: left;
  position: relative;
  background: ${props => props.background || "#fff"};
  color: ${props => props.color || "#000"};
  vertical-align: ${props => props.verticalAlign || 'top'};

  ${(props) => {
    const color = props.color || "#000";
    if (color !== "#000")
      return css`
        .ql-editor * {
          color: ${color} !important;
        }
      `;
  }}

  ${(props) =>
    props.align &&
    css`
      text-align: ${props.align};
      .ql-editor {
        *:not(.ql-align-center):not(.ql-align-right):not(.ql-align-left) {
          text-align: ${props.align};
        }
      }
    `}
  
  &:hover {
    ${HoverButtonCell} {
      opacity: 1;
    }
  }
`;

export const TableHeaderCellContainer = styled.div`
  width: 100%;
  .ql-fake * {
    cursor: default !important;
  }
`;

export const TableHeaderCell = styled.th`
  border: 1px solid #ddd;
  text-align: left;
  position: relative;
  background-color: ${({ isSelected }) => (isSelected ? "#f0f8ff" : "transparent")};

  strong {
    font-weight: bold;
  }

  ${(props) => {
    const color = getColumnFontColor(props);
    if (color !== "#000")
      return css`
        .ql-editor * {
          color: ${color} !important;
        }
      `;
  }}

  ${(props) =>
    props.col.align &&
    css`
      text-align: ${props.col.align};
      .ql-editor {
        *:not(.ql-align-center):not(.ql-align-right):not(.ql-align-left) {
          text-align: ${props.col.align};
        }
      }
    `}
  &:hover {
    ${HoverButtonColumn} {
      opacity: 1 !important;
    }
  }
`;

export const RowButtonCell = styled.td`
  position: relative;
  width: 0;
`;

export const OptionList = styled.div`
  display: flex;
  flex-direction: column;
`;
export const WrapperOption = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background: #f5f5f5;
  }
`;

export const OptionTitle = styled.p`
  font-size: 18px;
`;

export const Resizable = styled.div`
  display: inline-block;
  resize: both;
  overflow: hidden;
  transition: 0s;
  &:hover {
    border: 1px solid #ccc;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
