import React, { createContext, useContext, useEffect, useState } from "react";
import { useTableContext } from "./table-context";
import { useSelect } from "../hooks/useSelect";
import { createCell, createCol, createRow, createTextCell } from "../../../utils/table";
import { useOverlay } from "../hooks/useOverlay";

export const TableClipBoardContext = createContext(null);

export const TableClipBoardProvider = ({ children, handler, actions }) => {
  const [clipboardData, setClipboardData] = useState([]);
  const [activeEditor, setActiveEditor] = useState(false);
  const { selectedCell, selectedRow, selectedColumn } = handler;
  const { onChange, tableData: data } = useTableContext();
  const select = useSelect(data);

  useEffect(() => {
    if (selectedColumn || selectedRow) {
      let cells = [];

      if (selectedColumn) {
        data.rows.forEach((row) => {
          if (row.cells[selectedColumn]) {
            cells.push({ column: selectedColumn, row: row.id });
          }
        });
      }

      if (selectedRow) {
        data.rows.forEach((row) => {
          if (row.id === selectedRow) {
            const columns = Object.keys(row.cells);
            columns.forEach((colId) => {
              cells.push({ column: colId, row: selectedRow });
            });
          }
        });
      }

      select.setSelectedList(cells);
    }
  }, [selectedColumn, selectedRow]);

  const clearClipboard = async () => {
    try {
      await navigator.clipboard.writeText("");
    } catch (err) {
      console.error("Failed to clear clipboard: ", err);
    }
  };

  const getCell = (colId, rowId) => {
    const rowofSet = data.rows.find((item) => item.id === rowId);
    if (rowofSet) return rowofSet.cells[colId];
  };

  const handleCopy = () => {
    setClipboardData(select.selectedList);
    clearClipboard();
  };

  const handlePasteUniqueCell = () => {
    if (!clipboardData.length || clipboardData.length > 1) return;
    const cell = getCell(clipboardData[0].column, clipboardData[0].row);
    actions.changeCellRow(cell, selectedCell.column, selectedCell.row);
  };

  const handlePasteMultipleCell = () => {
    const rows = JSON.parse(JSON.stringify(data.rows));
    const columns = JSON.parse(JSON.stringify(data.columns));
    const startRowIndex = rows.findIndex((row) => row.id === selectedCell.row);
    const startColumnIndex = columns.findIndex((column) => column.id === selectedCell.column);

    if (startRowIndex === -1 || startColumnIndex === -1) {
      console.error("Célula de início não encontrada.");
      return;
    }

    clipboardData.forEach((clipboardCell) => {
      const clipboardRowIndex = rows.findIndex((row) => row.id === clipboardCell.row);
      const clipboardColumnIndex = columns.findIndex((column) => column.id === clipboardCell.column);

      if (clipboardRowIndex === -1 || clipboardColumnIndex === -1) return;

      const rowOffset = clipboardRowIndex - rows.findIndex((row) => row.id === clipboardData[0].row);
      const colOffset = clipboardColumnIndex - columns.findIndex((column) => column.id === clipboardData[0].column);

      const targetRow = startRowIndex + rowOffset;
      const targetCol = startColumnIndex + colOffset;

      if (targetRow >= rows.length) {
        const newRow = createRow(columns);

        rows.push(newRow);
      }

      if (targetCol >= columns.length) {
        const newColumn = createCol();
        columns.push(newColumn);

        rows.forEach((row) => {
          row.cells[newColumn.id] = createCell();
        });
      }

      const targetColumnId = columns[targetCol].id;
      rows[targetRow].cells[targetColumnId] = rows[clipboardRowIndex].cells[columns[clipboardColumnIndex].id];
    });

    onChange({
      data: {
        ...data,
        rows,
        columns,
      },
    });

    select.setSelectedList([]);
  };

  const pasteExcelData = (pastedData) => {
    const rows = JSON.parse(JSON.stringify(data.rows)); // Copiar dados da tabela
    const columns = JSON.parse(JSON.stringify(data.columns));

    const startRowIndex = rows.findIndex((row) => row.id === selectedCell.row);
    const startColumnIndex = columns.findIndex((column) => column.id === selectedCell.column);

    if (startRowIndex === -1 || startColumnIndex === -1) {
      console.error("Célula de início não encontrada.");
      return;
    }

    // Separar o conteúdo colado em linhas e células
    const clipboardRows = pastedData.split("\n").map((row) => row.split("\t"));

    // Verificar o número de linhas e colunas necessárias
    const endRowIndex = startRowIndex + clipboardRows.length - 1;
    const endColumnIndex = startColumnIndex + clipboardRows[0].length - 1;

    // Adicionar novas linhas, se necessário
    for (let i = rows.length; i <= endRowIndex; i++) {
      rows.push(createRow(columns));
    }

    // Adicionar novas colunas, se necessário
    for (let i = columns.length; i <= endColumnIndex; i++) {
      const newColumn = createCol();
      columns.push(newColumn);

      // Adicionar a nova coluna a todas as linhas existentes
      rows.forEach((row) => {
        row.cells[newColumn.id] = createCell();
      });
    }

    // Colar os dados na tabela
    clipboardRows.forEach((rowValues, rowIndex) => {
      rowValues.forEach((cellValue, colIndex) => {
        const targetRow = startRowIndex + rowIndex;
        const targetCol = startColumnIndex + colIndex;

        if (targetRow < rows.length && targetCol < columns.length) {
          const targetColumnId = columns[targetCol].id;
          rows[targetRow].cells[targetColumnId] = { type: "text", value: createTextCell(cellValue.trim()) }; // Adiciona o valor da célula colada
        }
      });
    });

    onChange({
      data: {
        ...data,
        rows,
        columns,
      },
    });

    select.setSelectedList([]);
  };

  const handlePasteFromExcel = (event) => {
    // Pega o conteúdo do clipboard
    const clipboardData = event.clipboardData || window.clipboardData;
    if (clipboardData) {
      console.log(clipboardData.getData("text/html"));
      const pastedData = clipboardData.getData("text"); // Captura o conteúdo colado como texto
      if (pastedData) {
        pasteExcelData(pastedData);
      }
    }
  };

  const handlePaste = (e) => {
    handlePasteFromExcel(e);
    handlePasteUniqueCell();
    handlePasteMultipleCell();
  };

  const handleDeleteCells = () => {
    if (activeEditor) return;

    const rows = JSON.parse(JSON.stringify(data.rows));

    select.selectedList.forEach((cellSelected) => {
      rows.forEach((row) => {
        if (cellSelected.row === row.id) {
          const cell = row.cells[cellSelected.column];
          if (cell) {
            row.cells[cellSelected.column] = createCell();
          }
        }
      });
    });

    onChange({
      data: {
        ...data,
        rows,
      },
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      handleDeleteCells();
    }

    if ((event.ctrlKey || event.metaKey) && event.key === "c") {
      handleCopy();
    }

    if ((event.ctrlKey || event.metaKey) && event.key === "v") {
      handlePaste(event);
    }
  };

  useEffect(() => {
    if (selectedCell) {
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("paste", handlePasteFromExcel);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("paste", handlePasteFromExcel);
    };
  }, [select.selectedList, activeEditor]);

  return (
    <TableClipBoardContext.Provider
      value={{
        clipboardData,
        setActiveEditor,
        ...select,
      }}
    >
      {children}
    </TableClipBoardContext.Provider>
  );
};

export const useClipboard = () => {
  return useContext(TableClipBoardContext);
};
