import React from "react";
import * as S from "./table.style";
import TableEditableCell from "./table-editable-cell";

export const TableRow = ({ handler, row, data, onChange }) => {
  return (
    <S.TableRow onMouseEnter={() => handler.handleMouseEnterRow(row.id)} onMouseLeave={handler.handleMouseLeaveRow}>
      {data.columns.map((col) => (
        <TableEditableCell
          key={`${col.id}x${row.id}`}
          handler={handler}
          col={col}
          data={data}
          row={row}
          onChange={(newCell) => onChange(newCell, col.id, row.id)}
        />
      ))}
    </S.TableRow>
  );
};
