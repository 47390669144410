import React, { useEffect, useRef, useState } from "react";

export const useResize = (initialValue = { width: 300, height: 200 }, callback) => {
  const [size, setSize] = useState(initialValue);
  const [isResizing, setIsResizing] = useState(false);
  const elementTarget = useRef(null);

  const checkNewSize = (newSize) => {
    const condition =
      newSize.width > size.width ||
      newSize.height > size.height ||
      newSize.width + 10 < size.width ||
      newSize.height + 10 < size.height;

    return condition;
  };

  const onMouseDown = (e) => {
    setIsResizing(true);
    elementTarget.current = e.target;
  };

  const onMouseEnter = (e) => {
    if (isResizing) {
      const newSize = { width: e.target.clientWidth, height: e.target.clientHeight };
      setSize(newSize);
      callback(newSize);
    }
  };

  const onMouseUp = (e) => {
    if (isResizing) {
      const newSize = { width: e.target.clientWidth, height: e.target.clientHeight };

      if (checkNewSize(newSize)) {
        setSize(newSize);
        callback(newSize);
        setIsResizing(false);
      }
    }
  };

  const resize = {
    finishResize: onMouseUp,
    startResize: onMouseDown,
    onResizing: onMouseEnter,
  };

  useEffect(() => {
    if (initialValue && JSON.stringify(initialValue) !== JSON.stringify(size)) setSize(initialValue);
  }, [initialValue]);

  return {
    size,
    resize,
  };
};
