import { UserService } from "../../../../services/user";

export function getImageUrl(image) {
  var accessToken = UserService.getUser().access_token;
  if (
    image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
    image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg"
  ) {
    image += "&access_token=" + accessToken;
  }
  return image;
}

export function getFont(font) {
  if (font) return font.replace(/\s/g, "-").toLowerCase();
}
