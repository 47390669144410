import React from "react";
import { getPosition } from "./utils";
import { Popover } from "@material-ui/core";
import styled from "styled-components";

const Wrapper = styled.div`
  min-width: 200px;
  min-height: 100px;
`;

export default function CustomPopover({ open, children, arrow = "top-left", hiddenArrow, sx, wrapperSyles, ...other }) {
  const { anchorOrigin, transformOrigin } = getPosition(arrow);

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      {...other}
    >
      <Wrapper style={wrapperSyles}>{children}</Wrapper>
    </Popover>
  );
}
