import styled, { css } from "styled-components";

export const ButtonAction = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: #efefef;
  border-radius: 5px;
  padding: 3px;
  align-items: center;
  color: #676767;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  ${(props) =>
    props.vertical &&
    css`
      width: 26px;
      top: 3.5rem;
      right: -36px;
      bottom: 0;
    `}
`;
