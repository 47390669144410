import React from "react";
import * as S from "./table.style";
import Quill from "../../components/quill/quill";
import { useDebouncedChange } from "./hooks/useDebouncedChange";
import { useTableContext } from "./context/table-context";
import { useResize } from "./hooks/useResize";
import { useClipboard } from "./context/table-clipboard-context";

const getInitialSize = (col) => {
  if (col.size)
    return {
      width: col.size.width,
      height: col.size.height,
    };

  return { width: 300, height: 40 };
};

export const TableEditableHeader = ({ handler, col, data, onChange }) => {
  const { content } = useTableContext();
  const { setSelectedList, setActiveEditor } = useClipboard();
  const { headers_color, headers_background } = content;
  const initialSize = getInitialSize(col);
  const { size } = useResize(initialSize, (newSize) => {
    onChange({ ...col, size: newSize });
  });

  const [value, setValue] = useDebouncedChange(col.header, 300, (newValue) => {
    onChange({
      ...col,
      header: newValue,
    });
  });

  const { background_color, column_background_color, font_color, column_font_color } = col;

  return (
    <S.TableHeaderCell
      col={col}
      onContextMenu={(e) => {
        e.preventDefault();
        handler.handleColumnClick(col.id, e);
      }}
      onClick={(e) => {
        if (e.button === 2) return;
        handler.handleColumnCellClick(col);
        setSelectedList([]);
      }}
      isSelected={handler.selectedColumn === col.id}
      onMouseEnter={(e) => {
        handler.handleMouseEnterColumn(col.id);
      }}
      onMouseLeave={handler.handleMouseLeaveColumn}
      className={col.id === handler.selectedColumn ? "col-selected" : "col"}
      style={{
        background: background_color || column_background_color || headers_background || content.headers_background,
        ...(headers_color && {
          color: headers_color,
        }),
        minWidth: size.width + "px",
      }}
    >
      <S.TableHeaderCellContainer>
        <S.TableCellContainer>
          {handler.selectedCellColumn === col.id ? (
            <Quill
              onBlur={() => {
                setActiveEditor(false);
              }}
              onFocus={() => {
                setActiveEditor(true);
              }}
              value={value || ""}
              onChange={(newValue) => setValue(newValue)}
              suppressChangeUnmount
            />
          ) : (
            <div
              style={{ cursor: "pointer " }}
              className="ql-editor ql-fake"
              dangerouslySetInnerHTML={{ __html: value }}
            />
          )}
        </S.TableCellContainer>
      </S.TableHeaderCellContainer>
    </S.TableHeaderCell>
  );
};
