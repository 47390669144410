import React from "react";
import CustomPopover from "../../components/custom-popover/custom-popover";
import { AddIcon } from "./components/icons/add";
import { IconTrash } from "./components/icons/trash";
import * as S from "./table-options.style";
import { LineMdArrowDown } from "./components/icons/arrow-down";
import { LineMdArrowUp } from "./components/icons/arrow-up";
import { IconAlign } from "./components/icons/align";
import { IconColor } from "./components/icons/color";
import ColorPickerComponent from "../../components/color-picker/color-picker";
import { useTableContext } from "./context/table-context";
import { IconSelect } from "./components/icons/select";
import { useClipboard } from "./context/table-clipboard-context";
import { IconText } from "./components/icons/text";
import { IconImage } from "./components/icons/image";

export const TablePopover = ({ handler, actions }) => {
  const { activeRow: row, selectedRow, selectedCell } = handler;
  const { selectedList, selectedRowList, selectedColumns, setSelectedList } = useClipboard();
  const { tableData: data } = useTableContext();

  const getCell = (colId, rowId) => {
    const rowofSet = data.rows.find((item) => item.id === rowId);
    if (rowofSet) return rowofSet.cells[colId];
  };

  const activeCell = selectedCell && getCell(selectedCell.column, selectedCell.row);

  const activeOptionAlign = (align) => {
    if (!row) return false;

    return row.align === align;
  };

  const { labels } = useTableContext();

  const getRows = () => {
    let rows = selectedRowList;
    if (!rows.length && selectedCell) {
      rows = [selectedCell.row];
    }
    return rows;
  };

  const onRemoveRow = () => {
    let rows = getRows();
    actions.removeRow(rows);
    handler.popover.onClose();
  };

  const onSelectRow = () => {
    let rows = getRows();
    const cells = [];
    rows.forEach((rowId) => {
      const row = data.rows.find((item) => item.id === rowId);
      if (row) {
        Object.keys(row.cells).forEach((colId) => {
          cells.push({ column: colId, row: row.id });
        });
      }
    });

    setSelectedList(cells);
  };

  const renderAlignVertical = (
    <CustomPopover
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={handler.popoverAlignVertical.open}
      onClose={handler.popoverAlignVertical.onClose}
    >
      <S.OptionItem
        onClick={() => {
          actions.changeCellsProperty(selectedList, "top", "vertical_align");
        }}
      >
        <IconAlign.VerticalTop />
        <S.OptionTitle>Topo</S.OptionTitle>
      </S.OptionItem>
      <S.OptionItem
        onClick={() => {
          actions.changeCellsProperty(selectedList, "middle", "vertical_align");
        }}
      >
        <IconAlign.VerticalCenter />
        <S.OptionTitle>Centro</S.OptionTitle>
      </S.OptionItem>
      <S.OptionItem
        onClick={() => {
          actions.changeCellsProperty(selectedList, "bottom", "vertical_align");
        }}
      >
        <IconAlign.VerticalBottom />
        <S.OptionTitle>Inferior</S.OptionTitle>
      </S.OptionItem>
    </CustomPopover>
  );

  const renderAlignHorizontal = (
    <CustomPopover
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={handler.popoverAlignHorizontal.open}
      onClose={handler.popoverAlignHorizontal.onClose}
    >
      <S.OptionItem
        active={activeOptionAlign("left")}
        onClick={() => {
          actions.changeCellsProperty(selectedList, "left", "align");
        }}
      >
        <IconAlign.Left />
        <S.OptionTitle>Esquerda</S.OptionTitle>
      </S.OptionItem>
      <S.OptionItem
        active={activeOptionAlign("center")}
        onClick={() => {
          actions.changeCellsProperty(selectedList, "center", "align");
        }}
      >
        <IconAlign.Center />
        <S.OptionTitle>Centro</S.OptionTitle>
      </S.OptionItem>
      <S.OptionItem
        active={activeOptionAlign("right")}
        onClick={() => {
          actions.changeCellsProperty(selectedList, "right", "align");
        }}
      >
        <IconAlign.Right />
        <S.OptionTitle>Direita</S.OptionTitle>
      </S.OptionItem>
    </CustomPopover>
  );

  return (
    <>
      <CustomPopover open={handler.popover.open} onClose={handler.popover.onClose}>
        <S.OptionList>
          {selectedRowList.length === 1 && (
            <>
              <S.OptionItem onClick={() => actions.addRow(handler.selectedRowIndex, "up")}>
                <AddIcon />
                <S.OptionTitle>Iserir 1 linha acima</S.OptionTitle>
              </S.OptionItem>
              <S.OptionItem onClick={() => actions.addRow(handler.selectedRowIndex)}>
                <AddIcon />
                <S.OptionTitle>Iserir 1 linha abaixo</S.OptionTitle>
              </S.OptionItem>
            </>
          )}
          {selectedRowList.length === 1 && (
            <>
              {handler.canRowChangePosition("up") && (
                <S.OptionItem onClick={() => actions.changeRowPosition(handler.selectedRowIndex, "up")}>
                  <LineMdArrowUp />
                  <S.OptionTitle>Mover linha para cima</S.OptionTitle>
                </S.OptionItem>
              )}

              {handler.canRowChangePosition("down") && (
                <S.OptionItem onClick={() => actions.changeRowPosition(handler.selectedRowIndex, "down")}>
                  <LineMdArrowDown />
                  <S.OptionTitle>Mover linha para baixo</S.OptionTitle>
                </S.OptionItem>
              )}
            </>
          )}
          <S.OptionItem onClick={onSelectRow}>
            <IconSelect />
            <S.OptionTitle>{selectedList.length > 1 ? "Selecionar linhas" : "Selecionar linha"}</S.OptionTitle>
          </S.OptionItem>
          <S.Divider />

          {/* <S.OptionItem
            onClick={() => {
              actions.clearRow(selectedRowList);
              handler.popover.onClose();
            }}
          >
            <IconClear />
            <S.OptionTitle>Limpar linha</S.OptionTitle>
          </S.OptionItem> */}
          {data.rows.length > 1 && (
            <S.OptionItem onClick={onRemoveRow}>
              <IconTrash />
              {selectedRowList.length > 1 ? (
                !selectedRow && <S.OptionTitle>Excluir linhas selecionadas</S.OptionTitle>
              ) : (
                <>
                  <S.OptionTitle>Excluir linha</S.OptionTitle>
                </>
              )}
            </S.OptionItem>
          )}

          {selectedColumns.length < data.columns.length && data.columns.length > 1 && (
            <S.OptionItem
              onClick={() => {
                actions.removeColumn(selectedColumns);
                handler.popover.onClose();
              }}
            >
              <IconTrash />
              {selectedColumns.length > 1 ? (
                <S.OptionTitle>Excluir colunas</S.OptionTitle>
              ) : (
                <S.OptionTitle>Excluir coluna</S.OptionTitle>
              )}
            </S.OptionItem>
          )}

          <S.Divider />

          <S.OptionItem onClick={handler.popoverAlignHorizontal.onOpen}>
            <IconAlign.Left />
            <S.OptionTitle>Alinhamento horizontal</S.OptionTitle>
          </S.OptionItem>

          <S.OptionItem onClick={handler.popoverAlignVertical.onOpen}>
            <IconAlign.VerticalTop />
            <S.OptionTitle>Alinhamento vertical</S.OptionTitle>
          </S.OptionItem>

          <S.Divider />

          {selectedList.length === 1 && (
            <S.OptionList>
              {activeCell && activeCell.type !== "text" && (
                <S.OptionItem
                  onClick={() => {
                    actions.changeCellType(handler.selectedCell, "text");
                    handler.popover.onClose();
                  }}
                >
                  <IconText />
                  <S.OptionTitle>Texto</S.OptionTitle>
                </S.OptionItem>
              )}

              <S.OptionItem
                onClick={() => {
                  actions.changeCellType(handler.selectedCell, "image", activeCell);
                  actions.editCell(handler.selectedCell);
                  handler.popover.onClose();
                }}
              >
                <IconImage />
                <S.OptionTitle>Imagem</S.OptionTitle>
              </S.OptionItem>

              <S.Divider />
            </S.OptionList>
          )}

          <ColorPickerComponent
            labels={labels}
            onChange={(color) => actions.changeColorSelectedCells(selectedList, color)}
            color={(row || {}).font_color || "#000000"}
          >
            <S.OptionItem>
              <IconColor.Text />
              <S.OptionTitle>Cor do texto</S.OptionTitle>
            </S.OptionItem>
          </ColorPickerComponent>

          <ColorPickerComponent
            labels={labels}
            onChange={(color) => actions.changeBgColorSelectedCells(selectedList, color)}
            color={(row || {}).background_color || "#ffffff"}
          >
            <S.OptionItem>
              <IconColor.Fill />
              <S.OptionTitle>Cor do fundo</S.OptionTitle>
            </S.OptionItem>
          </ColorPickerComponent>
        </S.OptionList>
      </CustomPopover>

      {renderAlignHorizontal}

      {renderAlignVertical}
    </>
  );
};
