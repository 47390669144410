import styled, { css } from "styled-components";

export const OptionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const OptionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      background-color: #c4cdc763;
    `}
  &:hover {
    background: #f5f5f5;
    color: #000;
  }
`;

export const OptionTitle = styled.p`
  font-size: 18px;
`;

export const Divider = styled.div`
  width: 100;
  height: 1px;
  background-color: #ddd;
`;
