import styled, { css } from "styled-components";

export const ElementTimeline1 = styled.div`
  width: 100%;
  padding-top: ${props => props.paddingTop || 0}px;
  padding-bottom: ${props => props.paddingBottom || 0}px;
  background-color: ${props => props.backgroundColor || "#fff"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  overflow: hidden;
`;

export const TimelineList = styled.ul`
  li {
    margin-bottom: 50px;
  }
  
  li:last-child {
    margin-bottom: 0;
  }
`;

export const TimelineItem = styled.li`
  width: 100%;
  max-width: 800px;
  padding: 40px;
  border: 1px solid #e6e6e6;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-left: 5px solid ${props => props.primaryColor || "#00643e"};
  border-radius: 8px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    height: 50px;
    width: 1px;
    border-left: 1px solid ${props => props.primaryColor || "grey"};
  }
  
  &:last-child:before {
    border-left: none;
  }
`;

export const TimelineDate = styled.p`
  color: ${props => props.primaryColor || "#00643e"};
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 1.3rem;
`;

export const TimelineTitle = styled.p`
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 30px;
`;

export const TimelineDescription = styled.p`
  font-size: 1.2rem;
  overflow-y: ${(props) => (props.height ? "auto" : "hidden")};
  height: ${(props) => (props.height ? props.height : "auto")};

  ${(props) =>
    props.height &&
    css`
      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #afafaf;
      }
    `}
`;

export const StyledQlEditor = styled(TimelineDescription)`
&.ql-editor {
  font: super !important;

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin: 1em 0 0.5em;
  }

  p {
    margin: 0 0 1em;
  }

  strong, b {
    font-weight: bold !important;
  }

  em, i {
    font-style: italic !important;
  }

  u {
    text-decoration: underline !important;
  }

  ol, ul {
    padding-left: 2em !important;
    margin: 0 0 1em;
  }

  ol {
    list-style-type: decimal !important;
  }

  ul {
    list-style-type: disc !important;
  }

  li {
    display: list-item !important;
  }
}
`;
export const TimelineImage = styled.img`
  width: 100%;
  margin: 20px 0;
`;

export const TimelineAudio = styled.audio`
  width: 100%;
  margin: 20px 0;
`;