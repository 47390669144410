import styled, {css} from "styled-components";

export const ElementSteps1 = styled.div`
  background-color: ${props => props.backgroundColor || "#fff"};
  padding-top: ${props => props.paddingTop || "0"}px;
  padding-bottom: ${props => props.paddingBottom || "0"}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

export const CardList = styled.ul`
  max-width: 1000px;
  position: relative;
  margin: auto;
  display: inline-block;
`;

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  gap: 20px;
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  color: ${props => props.fontColor || "white"};
  background: ${props => props.backgroundColor || "#00643e"};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  &:active, &:disabled {
    animation: none;
  }

  &:hover {
    filter: brightness(1.3);
  }

  &:disabled {
    cursor: default;
    opacity: 0;
  }

  &:focus {
    outline: 2px solid ${props => props.backgroundColor || "#00643e"} !important;
    outline-offset: 2px;
  }
`;

export const ChangeCardButton = styled(Button)`
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const Card = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 40px;
  border: 1px solid #e6e6e6;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  position: absolute;

  ${props => props.position === "right" && css`
    margin-left: 100%;
    position: absolute;
    top: 0;
    pointer-events: none;
  `}

  ${props => props.position === "left" && css`
    margin-left: -100%;
    position: absolute;
    pointer-events: none;
  `}

  ${props => props.position === "center" && css`
    opacity: 1;
    transition: all 0.2s ease-in-out;
    transition-delay: 0.3s;
    position: relative;
    border-left: 5px solid ${props => props.primaryColor || "#00643e"};
  `}
`;

export const CardTitle = styled.p`
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 30px;
  text-align: ${props => props.align};
`;

export const CardDescription = styled.p`
  font-size: 1.2rem;

    &.ql-editor {
    font: super !important;

    h1, h2, h3, h4, h5, h6 {
      font-weight: bold;
      margin: 1em 0 0.5em;
    }

    p {
      margin: 0 0 1em;
    }

    strong, b {
      font-weight: bold !important;
    }

    em, i {
      font-style: italic !important;
    }

    u {
      text-decoration: underline !important;
    }

    ol, ul {
      padding-left: 2em !important;
      margin: 0 0 1em;
    }

    ol {
      list-style-type: decimal !important;
    }

    ul {
      list-style-type: disc !important;
    }

    li {
      display: list-item !important;
    }
  }
`;

export const CardImage = styled.img`
  width: 100%;
  margin: 20px 0;
`;

export const CardAudio = styled.audio`
  width: 100%;
  margin: 20px 0;
`;

export const StartButton = styled(Button)`
  width: 300px;
  border-radius: 8px;
  margin: 20px auto;
`;

export const RetryButton = styled(Button)`
  margin: 20px auto;
  border-radius: 0;
  height: auto;
  width: auto;
  background-color: transparent;
  color: ${props => props.fontColor || "#00643e"};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

export const ProgressList = styled.ul`
  margin: 50px auto 0 auto;
  text-align: center;
`;

export const ProgressItem = styled.li`
  display: inline-block;
  padding: 5px;
  margin: 2px;
  cursor: pointer;
  width: 30px;
  height: 30px;

  ${props => props.active && css`
    border: 2px solid ${props => props.primaryColor || "#00643e"};
    border-radius: 100%;
    color: ${props => props.primaryColor || "#00643e"};
  `}
`;


export const StepBadge = styled.span`
  position: absolute;
  left: calc(50% - 50px);
  top: -25px;
  width: 100px;
  height: 50px;
  background-color: ${props => props.primaryColor || "#00643e"};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 1.2rem;
  color: #fff;
`;