import React from "react";
import * as S from "./table-add-action.style";
import { AddIcon } from "../icons/add";

export const TableAddActions = ({ onAddRow, onAddColumn }) => {
  const onMouseDown = () => {};

  const onMouseUp = () => {};

  return (
    <>
      <S.ButtonAction draggable onMouseUp={onMouseUp} onClick={onAddRow} onMouseDown={onMouseDown}>
        <AddIcon />
      </S.ButtonAction>
    </>
  );
};
