import React, { useState } from "react";
import CustomPopover from "../../components/custom-popover/custom-popover";
import { AddIcon } from "./components/icons/add";
import { IconTrash } from "./components/icons/trash";
import { IconClear } from "./components/icons/clear";
import * as S from "./table-options.style";
import { LineMdArrowRight } from "./components/icons/arrow-right";
import { LineMdArrowLeft } from "./components/icons/arrow-left";
import { IconAlign } from "./components/icons/align";
import { IconColor } from "./components/icons/color";
import { useTableContext } from "./context/table-context";
import ColorPickerComponent from "../../components/color-picker/color-picker";
import { useClipboard } from "./context/table-clipboard-context";

export const TableHeaderOptions = ({ handler, actions }) => {
  const { activeColumn: column } = handler;
  const [activeColor, setActiveColor] = useState(null);
  const { selectedList } = useClipboard();
  const activeOptionAlign = (align) => {
    if (!column) return false;

    return column.align === align;
  };

  const { labels } = useTableContext();

  const renderAlignVertical = (
    <CustomPopover
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={handler.popoverAlignVerticalColumn.open}
      onClose={handler.popoverAlignVerticalColumn.onClose}
    >
      <S.OptionItem
        onClick={() => {
          actions.changeColumnAlignVertical(handler.selectedColumn, "top", selectedList);
        }}
      >
        <IconAlign.VerticalTop />
        <S.OptionTitle>Topo</S.OptionTitle>
      </S.OptionItem>
      <S.OptionItem
        onClick={() => {
          actions.changeColumnAlignVertical(handler.selectedColumn, "middle", selectedList);
        }}
      >
        <IconAlign.VerticalCenter />
        <S.OptionTitle>Centro</S.OptionTitle>
      </S.OptionItem>
      <S.OptionItem
        onClick={() => {
          actions.changeColumnAlignVertical(handler.selectedColumn, "bottom", selectedList);
        }}
      >
        <IconAlign.VerticalBottom />
        <S.OptionTitle>Inferior</S.OptionTitle>
      </S.OptionItem>
    </CustomPopover>
  );

  const renderAlignHorizontal = (
    <CustomPopover
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={handler.popoverAlignHorizontalColumn.open}
      onClose={handler.popoverAlignHorizontalColumn.onClose}
    >
      <S.OptionItem
        active={activeOptionAlign("left")}
        onClick={() => {
          actions.changeColumnAlignHorizontal(handler.selectedColumn, "left", selectedList);
        }}
      >
        <IconAlign.Left />
        <S.OptionTitle>Esquerda</S.OptionTitle>
      </S.OptionItem>
      <S.OptionItem
        active={activeOptionAlign("center")}
        onClick={() => {
          actions.changeColumnAlignHorizontal(handler.selectedColumn, "center", selectedList);
        }}
      >
        <IconAlign.Center />
        <S.OptionTitle>Centro</S.OptionTitle>
      </S.OptionItem>
      <S.OptionItem
        active={activeOptionAlign("right")}
        onClick={() => {
          actions.changeColumnAlignHorizontal(handler.selectedColumn, "right", selectedList);
        }}
      >
        <IconAlign.Right />
        <S.OptionTitle>Direita</S.OptionTitle>
      </S.OptionItem>
    </CustomPopover>
  );

  return (
    <>
      <CustomPopover open={handler.popoverColumn.open} onClose={handler.popoverColumn.onClose}>
        <S.OptionList>
          <S.OptionItem onClick={() => actions.addColumn(handler.selectedColumnIndex, "left")}>
            <AddIcon />
            <S.OptionTitle>Iserir 1 coluna à esquerda</S.OptionTitle>
          </S.OptionItem>
          <S.OptionItem onClick={() => actions.addColumn(handler.selectedColumnIndex, "right")}>
            <AddIcon />
            <S.OptionTitle>Iserir 1 coluna à direita</S.OptionTitle>
          </S.OptionItem>
          {handler.canColumnChangePosition("left") && (
            <S.OptionItem onClick={() => actions.changeColumnPosition(handler.selectedColumnIndex, "left")}>
              <LineMdArrowLeft />
              <S.OptionTitle>Mover coluna para esquerda</S.OptionTitle>
            </S.OptionItem>
          )}

          {handler.canColumnChangePosition("right") && (
            <S.OptionItem onClick={() => actions.changeColumnPosition(handler.selectedColumnIndex, "right")}>
              <LineMdArrowRight />
              <S.OptionTitle>Mover coluna para direita</S.OptionTitle>
            </S.OptionItem>
          )}

          <S.OptionItem
            onClick={() => {
              actions.clearColumn(handler.selectedColumn);
              handler.popoverColumn.onClose();
            }}
          >
            <IconClear />
            <S.OptionTitle>Limpar coluna</S.OptionTitle>
          </S.OptionItem>
          <S.OptionItem
            onClick={() => {
              actions.removeColumn([handler.selectedColumn]);
              handler.popoverColumn.onClose();
            }}
          >
            <IconTrash />
            <S.OptionTitle>Excluir coluna</S.OptionTitle>
          </S.OptionItem>

          <S.Divider />

          <S.OptionItem onClick={handler.popoverAlignHorizontalColumn.onOpen}>
            <IconAlign.Left />
            <S.OptionTitle>Alinhamento horizontal</S.OptionTitle>
          </S.OptionItem>

          <S.OptionItem onClick={handler.popoverAlignVerticalColumn.onOpen}>
            <IconAlign.VerticalTop />
            <S.OptionTitle>Alinhamento vertical</S.OptionTitle>
          </S.OptionItem>

          <S.Divider />

          <ColorPickerComponent
            labels={labels}
            keepOpen
            onChange={(color, e) => {
              handler.popoverColumnColor.onOpen(e);
              setActiveColor({
                property: "column_font_color",
                value: color,
              });
              // actions.changeColumnProperty(handler.selectedColumn, "column_font_color", color);
              // handler.popoverColumn.onClose();
            }}
            color={(column || {}).column_font_color || "#fff"}
          >
            <S.OptionItem>
              <IconColor.Text />
              <S.OptionTitle>Cor do texto</S.OptionTitle>
            </S.OptionItem>
          </ColorPickerComponent>

          <ColorPickerComponent
            labels={labels}
            keepOpen
            onChange={(color, e) => {
              handler.popoverColumnColor.onOpen(e);
              setActiveColor({
                property: "column_background_color",
                value: color,
              });
              // actions.changeColumnProperty(handler.selectedColumn, "column_background_color", color);
              // handler.popoverColumn.onClose();
            }}
            color={(column || {}).column_background_color || "#fff"}
          >
            <S.OptionItem>
              <IconColor.Fill />
              <S.OptionTitle>Cor do fundo</S.OptionTitle>
            </S.OptionItem>
          </ColorPickerComponent>
        </S.OptionList>
      </CustomPopover>

      <CustomPopover
        wrapperSyles={{
          width: "293px",
        }}
        open={handler.popoverColumnColor.open}
        onClose={handler.popoverColumnColor.onClose}
      >
        <S.OptionList>
          <S.OptionItem
            onClick={() => {
              if (activeColor) {
                actions.changeColumnProperty(handler.selectedColumn, activeColor.property, activeColor.value);
                handler.popoverColumn.onClose();
                handler.popoverColumnColor.onClose();
                setActiveColor(null);
              }
            }}
          >
            <IconColor.Full />
            <S.OptionTitle>Aplicar para toda coluna</S.OptionTitle>
          </S.OptionItem>
          <S.OptionItem
            onClick={() => {
              if (activeColor) {
                actions.changeColumnProperty(
                  handler.selectedColumn,
                  activeColor.property.replace("column_", ""),
                  activeColor.value
                );
                handler.popoverColumn.onClose();
                handler.popoverColumnColor.onClose();
                setActiveColor(null);
              }
            }}
          >
            <IconColor.Some />
            <S.OptionTitle>Aplicar apenas à célula</S.OptionTitle>
          </S.OptionItem>
        </S.OptionList>
      </CustomPopover>
    </>
  );
};
