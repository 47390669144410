import React, { Component } from "react";
import "./color-picker.css";
import ColorPicker from "react-best-gradient-color-picker";
import { getLocalStorageItem, setLocalStorageItem } from "../../utils/localStorageUtil";

class ColorPickerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active_color: props.color ? props.color : "#ffffff",
      opened: false,
      customColors: this.customColorsList(),
      colors: this.colorList(this.customColorsList()),
    };
  }

  customColorsList() {
    const customColors = JSON.parse(localStorage.getItem("custom_colors"));

    if (!customColors) {
      const colors = [
        "#007c00",
        "#00ff00",
        "#00ffff",
        "#245df2",
        "rgba(123.5836911488887, 2.004305099999992, 246.97769490000002, 1)",
        "rgba(255, 5.967000000000022, 125.50283999999994, 1)",
        "rgba(21.832999999999938, 1.9890000000000074, 255, 1)",
        "rgba(255, 125.87177777777778, 5.967000000000022, 1)",
        "rgba(0, 243.98400000000004, 141.09918072289162, 1)",
      ];
      setLocalStorageItem("custom_colors", colors);
      return colors;
    }

    return customColors;
  }
  colorList(customColors) {
    const baseColors = [
      "#000000",
      "#444444",
      "#535353",
      "#7c7c7c",
      "#ffffff",
      "#b60000",
      "#ff0000",
      "#ff9900",
      "#ffff00",
    ];

    return [...baseColors, ...this.customColorsList().filter((color) => !baseColors.includes(color))];
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.opened) {
      console.log("You clicked outside of me!");
      this.setState({ opened: false });
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  togglePicker = () => {
    this.setState({
      colors: this.colorList(this.customColorsList()),
    });
    this.setState({ opened: !this.state.opened });
  };

  onColorChange = (color) => {
    this.setState({ active_color: color });
  };

  saveColor = () => {
    const { active_color, customColors, colors } = this.state;

    if (customColors && !customColors.includes(active_color) && !colors.includes(active_color)) {
      const updatedColors =
        customColors.length === 9 ? [...customColors.slice(1), active_color] : [...customColors, active_color];

      this.setState({ customColors: updatedColors });
      setLocalStorageItem("custom_colors", updatedColors);
    }
  };

  handleConclude = (e) => {
    this.saveColor();
    this.props.onChange(this.state.active_color, e);
    if (!this.props.keepOpen) {
      this.togglePicker();
    }
  };

  render() {
    const { active_color, opened, colors } = this.state;
    const { labels } = this.props;

    return (
      <div ref={this.setWrapperRef} className={`rdp-composer-color-picker`}>
        <div
          style={{
            ...(!this.props.children && {
              background: active_color,
            }),
          }}
          className={!this.props.children ? "color-picker-box-color" : "color-picker-custom-box-color"}
        >
          {this.props.children ? (
            <div onClick={this.togglePicker}>{this.props.children}</div>
          ) : (
            <>
              <span className="active-color">{active_color}</span>
              <button onClick={this.togglePicker}>
                {opened
                  ? labels.elements.common_settings.label_color_done
                  : labels.elements.common_settings.label_color_change}
              </button>
            </>
          )}

          {opened && (
            <div className="color-picker-box-color-picker" style={{ 
              ...(this.props.children && {
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                width: '100%'
              })
            }}>
              <div className="color-picker-box-color-picker-overlay" onClick={this.togglePicker}></div>
              <div className="color-picker-box-color-picker-window">
                <ColorPicker
                  value={active_color}
                  onChange={this.onColorChange}
                  hideAdvancedSliders={true}
                  presets={colors}
                />
                <button className="color-picker-box-color-picker-button" onClick={this.handleConclude}>
                  {labels.elements.common_settings.label_color_done}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ColorPickerComponent;
