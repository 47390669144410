import React, { useMemo } from "react";
import { useTableContext } from "../../context/table-context";
import { TablePropertiesCell } from "./table-properties-cell";

export const TablePropertiesContainer = ({ open, onClose, children }) => {
  if (!open) return null;

  return (
    <div className={`rdp-composer-element-edit rdp-composer-element-edit-theme-`}>
      <div className="rdp-composer-element-edit-overlay" onClick={onClose}></div>
      <div className={`rdp-composer-element-edit-modal rdp-composer-element-edit-modal-theme-`}>
        <div className="rdp-composer-element-edit-header">
          <span className="rdp-composer-element-edit-header-title">Editar objeto</span>
          <span className="rdp-composer-element-edit-header-close" onClick={onClose}></span>
        </div>

        {children}
      </div>
    </div>
  );
};
