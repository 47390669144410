import { useState } from "react";
import { createCol, createRow, createTextCell } from "../../../utils/table";
import { useTableContext } from "../context/table-context";

export const useTableActions = () => {
  const { tableData: data, onChange, setActiveCell } = useTableContext();

  const changeColumnProperty = (columnId, property, value) => {
    const column = data.columns.find((item) => item.id === columnId);
    if (property.includes("column_background_color")) {
      delete column.background_color;
      delete column.column_background_color;
    }

    if (property.includes("column_font_color")) {
      delete column.font_color;
      delete column.column_font_color;
    }

    column[property] = value;
    changeCellColumn(column);
  };

  const processColumnAlign = (columnId, align, property = "align") => {
    const column = data.columns.find((item) => item.id === columnId);
    if (!column) return;
    column[property] = align;

    return data.columns.map((item) => (item.id === columnId ? column : item));
  };

  const changeCellColumn = (newColumn) => {
    onChange({
      data: {
        ...data,
        columns: data.columns.map((column) => {
          if (column.id === newColumn.id) return newColumn;
          return column;
        }),
      },
    });
  };

  const changeCellType = (positions, type, activeCell) => {
    const { column: colId, row: rowId } = positions;

    let valueBasedType =
      type === "image" ? "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" : createTextCell();

    if (type === "image" && activeCell && activeCell.value.includes("http")) valueBasedType = activeCell.value;
    
    onChange({
      data: {
        ...data,
        rows: data.rows.map((row) => {
          let cell = row.cells[colId];
          if (cell && row.id === rowId) {
            row.cells[colId] = {
              ...cell,
              type,
              value: valueBasedType,
            };
            console.log(cell);
          }
          return row;
        }),
      },
    });
  };

  const changeCellRow = (newCell, colId, rowId) => {
    let rows = [...data.rows];

    rows.forEach((itemRow) => {
      if (itemRow.id === rowId) {
        if (itemRow.cells[colId]) itemRow.cells[colId] = newCell;
      }
    });

    onChange({
      data: {
        ...data,
        rows,
      },
    });
  };

  const clearRow = (rowId) => {
    onChange({
      data: {
        ...data,
        rows: data.rows.map((item) => {
          if (item.id === rowId) return createRow(data.columns);
          return item;
        }),
      },
    });
  };

  const removeRow = (rowListId) => {
    const newData = data;
    console.log(rowListId);
    console.log(newData);

    rowListId.forEach((rowId) => {
      newData.rows = newData.rows.filter((row) => row.id !== rowId);
    });

    onChange({
      data: {
        ...data,
        rows: newData.rows,
      },
    });
  };

  const processRemoveColumn = (columnId, newData) => {
    const columns = JSON.parse(JSON.stringify(newData.columns));
    const rows = JSON.parse(JSON.stringify(newData.rows));

    const updatedColumns = columns.filter((column) => column.id !== columnId);
    const updatedRows = rows.map((item) => {
      const newCells = item.cells;
      delete newCells[columnId];
      return {
        ...item,
        cells: newCells,
      };
    });

    return {
      columns: updatedColumns,
      rows: updatedRows,
    };
  };

  const removeColumn = (columnIdList) => {
    let newData = data;

    columnIdList.forEach((columnId) => {
      newData = processRemoveColumn(columnId, newData);
    });

    onChange({
      data: {
        ...data,
        rows: newData.rows,
        columns: newData.columns,
      },
    });
  };

  const clearColumn = (columnId) => {
    const { columns, rows } = data;
    const updatedColumns = [...columns].map((item) => ({
      ...item,
      header: item.id === columnId ? createTextCell(" ", "#fff") : item.header,
    }));

    const updatedRows = [...rows];

    rows.forEach((row) => {
      if (row.cells[columnId]) {
        row.cells[columnId] = {
          type: "text",
          value: createTextCell(),
        };
      }
    });

    onChange({
      data: {
        ...data,
        columns: updatedColumns,
        rows: updatedRows,
      },
    });
  };

  const changeRowPosition = (rowIndex1, direction = "down") => {
    const rows = [...data.rows];
    const rowIndex2 = direction === "down" ? rowIndex1 + 1 : rowIndex1 - 1;

    [rows[rowIndex1], rows[rowIndex2]] = [rows[rowIndex2], rows[rowIndex1]];

    onChange({
      data: {
        ...data,
        rows,
      },
    });
  };

  const changeColumnPosition = (index, direction = "left") => {
    const columns = [...data.columns];
    const columnIndex2 = direction === "right" ? index + 1 : index - 1;

    [columns[index], columns[columnIndex2]] = [columns[columnIndex2], columns[index]];

    onChange({
      data: {
        ...data,
        columns,
      },
    });
  };

  const addColumn = (index, direction = "left") => {
    const { columns, rows } = data;

    const newColumn = createCol();
    const updatedColumns = [...columns];
    const updatedRows = [...rows];

    if (typeof index !== "number") {
      updatedColumns.push(newColumn);
    } else {
      index = direction === "right" ? index + 1 : index;
      updatedColumns.splice(index, 0, newColumn);
    }

    updatedRows.forEach((row) => {
      row.cells[newColumn.id] = {
        type: "text",
        value: createTextCell(),
      };
    });

    onChange({
      data: {
        ...data,
        columns: updatedColumns,
        rows: updatedRows,
      },
    });
  };

  const addRow = (index, direction = "down") => {
    const { rows, columns } = data;
    const newRow = createRow(columns);
    const updatedRows = [...rows];

    if (typeof index !== "number") {
      updatedRows.push(newRow);
    } else {
      index = direction === "down" ? index + 1 : index;
      updatedRows.splice(index, 0, newRow);
    }

    onChange({
      data: {
        ...data,
        rows: updatedRows,
      },
    });
  };

  const editCell = (cell) => {
    const rowFromCell = data.rows.find((row) => cell.row === row.id);
    const cellOfCellsInRow = rowFromCell.cells[cell.column];

    if (cellOfCellsInRow) setActiveCell(cellOfCellsInRow);
  };

  const changeCellsProperty = (selectedCells, value, property, columns) => {
    let updatedRows = [...data.rows];

    selectedCells.forEach((cell) => {
      const row = updatedRows.find((item) => item.id === cell.row);
      if (row.cells[cell.column]) {
        row.cells[cell.column] = {
          ...row.cells[cell.column],
          [property]: value,
        };
      }
      updatedRows = updatedRows.map((item) => (item.id === row.id ? row : item));
    });

    onChange({
      data: {
        ...data,
        rows: updatedRows,
        columns: columns ? columns : data.columns,
      },
    });
  };

  const changeColorSelectedCells = (selectedCells, value, property = "font_color") => {
    changeCellsProperty(selectedCells, value, property);
  };

  const changeBgColorSelectedCells = (selectedCells, value) => {
    changeCellsProperty(selectedCells, value, "background_color");
  };

  const changeColumnAlignHorizontal = (selectedColumn, value, selectedCells) => {
    const newColumns = processColumnAlign(selectedColumn, value, "align");
    changeCellsProperty(selectedCells, value, "align", newColumns);
  };

  const changeColumnAlignVertical = (selectedColumn, value, selectedCells) => {
    const newColumns = processColumnAlign(selectedColumn, value, "vertical_align");
    changeCellsProperty(selectedCells, value, "vertical_align", newColumns);
  };

  return {
    addColumn,
    addRow,
    clearRow,
    removeRow,
    clearColumn,
    removeColumn,
    changeRowPosition,
    changeColumnPosition,
    changeCellColumn,
    changeCellRow,
    changeCellType,
    changeColumnAlignHorizontal,
    changeColumnAlignVertical,
    changeColumnProperty,
    editCell,
    changeBgColorSelectedCells,
    changeColorSelectedCells,
    changeCellsProperty,
  };
};
