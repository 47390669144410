import React, { useState } from "react";
import ColorPickerComponent from "../../../../components/color-picker/color-picker";
import { useTableContext } from "../../context/table-context";
import { FontPicker, RDPDamWidget } from "../../../../components";
import { UserService } from "../../../../services/user";
import { Wrapper } from "./table-properties.style";
export const TablePropertiesCell = () => {
  const { activeCell, setActiveCell, labels } = useTableContext();
  const [dam, setDam] = useState(false);

  if (!activeCell) return;

  const { background_color, value, type, font_family, font_color, font_size } = activeCell;

  function getImageUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (
      image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
      image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg"
    ) {
      image += "&access_token=" + accessToken;
    }
    return image;
  }

  const onChangeCell = (newValue, property) => {
    setActiveCell({
      ...activeCell,
      [property]: newValue,
    });
  };

  function renderFont() {
    if (font_family) {
      return (
        <FontPicker
          font={font_family || "Lato"}
          onFontChange={(font) => {
            onChangeCell(font, "font_family");
          }}
        />
      );
    } else {
      return (
        <FontPicker
          hasFont={false}
          onFontChange={(font) => {
            onChangeCell(font, "font_family");
          }}
          noFontLabel={"Nenhuma fonte"}
        />
      );
    }
  }

  function renderDAMWidget() {
    if (dam === true) {
      return (
        <RDPDamWidget
          labels={labels}
          type="image"
          onClose={() => setDam(false)}
          onAssetChange={(asset) => {
            onChangeCell(asset, "value");
            setDam(false);
          }}
        />
      );
    }
  }

  return (
    <Wrapper className="rdp-element-table-properties">
      <ul className="rdp-composer-element-edit-tabs">
        <li className={["rdp-composer-element-edit-tabs-item", "active"].join(" ")}>Configurações</li>
      </ul>

      <div className="rdp-composer-element-edit-body">
        <span className="rdp-composer-element-edit-label">Cor do fundo</span>
        <ColorPickerComponent
          labels={labels}
          color={background_color || "#ffffff"}
          onChange={(newColor) => {
            onChangeCell(newColor, "background_color");
          }}
        />

        {type === "image" && (
          <>
            <span className="rdp-composer-element-edit-label">Imagem da célula</span>
            <img
              className="rdp-composer-element-image"
              onClick={() => setDam(true)}
              alt="Composer"
              src={getImageUrl(value)}
            />
          </>
        )}
        {type !== "image" && (
          <>
            <span className="rdp-composer-element-edit-label">Cor da fonte</span>
            <ColorPickerComponent
              labels={labels}
              color={font_color || "#000000"}
              onChange={(newColor) => {
                onChangeCell(newColor, "font_color");
              }}
            />

            <span className="rdp-composer-element-edit-label">Fonte do texto</span>
            {renderFont()}

          </>
        )}
      </div>

      {renderDAMWidget()}
    </Wrapper>
  );
};
