import { useEffect, useState } from "react";

export const useSelect = (data) => {
  const { columns, rows } = data || {};

  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [overlayStyles, setOverlayStyles] = useState({});

  const rowsSelected = () => {
    const rowList = [];

    selectedList.forEach((cell) => {
      if (!rowList.includes(cell.row)) rowList.push(cell.row);
    });

    return rowList;
  };

  const columnsSelected = () => {
    const columnList = [];

    selectedList.forEach((cell) => {
      if (!columnList.includes(cell.column)) columnList.push(cell.column);
    });
    return columnList;
  };

  const isSelected = (cell) => {
    return selectedList.some((item) => item.column === cell.column && item.row === cell.row);
  };

  const onStartSelect = (cell) => {
    setIsSelecting(true);
    setSelectedList([cell]);
  };

  const onEndSelect = () => {
    setIsSelecting(false);
  };

  const onSelecting = (cell) => {
    if (!isSelecting || isSelected(cell)) return;

    const startCell = selectedList[0]; // Célula inicial
    const endCell = cell;

    // Obter os índices da linha e coluna
    const startRowIndex = rows.findIndex((row) => row.id === startCell.row);
    const endRowIndex = rows.findIndex((row) => row.id === endCell.row);
    const startColumnIndex = columns.findIndex((column) => column.id === startCell.column);
    const endColumnIndex = columns.findIndex((column) => column.id === endCell.column);

    // Criar uma seleção entre a célula inicial e a célula atual
    const selectedCells = new Set(); // Usando Set para evitar duplicatas

    for (
      let rowIndex = Math.min(startRowIndex, endRowIndex);
      rowIndex <= Math.max(startRowIndex, endRowIndex);
      rowIndex++
    ) {
      for (
        let colIndex = Math.min(startColumnIndex, endColumnIndex);
        colIndex <= Math.max(startColumnIndex, endColumnIndex);
        colIndex++
      ) {
        selectedCells.add(JSON.stringify({ row: rows[rowIndex].id, column: columns[colIndex].id }));
      }
    }

    setSelectedList(Array.from(selectedCells).map((item) => JSON.parse(item)));
  };

  return {
    onStartSelect,
    onEndSelect,
    onSelecting,
    selectedList,
    setSelectedList,
    isSelected,
    selectedRowList: rowsSelected(),
    selectedColumns: columnsSelected(),
  };
};
