import React, { Component } from 'react';
import { Quill } from '../../components';
import { UserService } from '../../services/user';
import 'animate.css/animate.min.css';
import './element-image-with-text.css';
import HtmlString from "../../components/html-string/html-string";

export default class ElementImageWithText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageError: false,
    };
    this.headerVideoRef = React.createRef();
  }

  getBackgroundUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg") {
      image += "&access_token=" + accessToken;
    };
    return image;
  }

  handleTextChange(text) {
    var content = this.props.content;
    content.text = text;
    this.props.onChange(content);
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  getLegendStyle() {
    const legend_size = this.props.content.legend_size || "12";

    return {
      position: "relative",
      maxWidth: "760px",
      bottom: 0,
      left: 0,
      color: this.props.content.legend_color || 'white',
      paddingTop: this.props.content.padding_top + "px",
      paddingBottom: this.props.content.padding_bottom + "px",
      padding: "5px",
      width: "100%",
      textAlign: this.props.content.legend_align || "esquerda",
      fontSize: `${legend_size}px`,
      margin: 'auto'
    };
  }

  renderVideoBackground() {
    const accessToken = UserService.getUser().access_token;
    return (
      <video
        ref={this.headerVideoRef}
        className="rdp-element-head-1-video-mask"
        autoPlay
        loop
        muted
        src={`${this.props.content.background_video}&access_token=${accessToken}`}
      />
    );
  }

  render() {
    let { headerActionsTemplate, footerActionsTemplate, content, className } = this.props;
    const containerStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    };

    return (
      <div className={`${className} ${this.getFont(content.font_family) || ""}`} style={containerStyle}>
        {headerActionsTemplate}
        {content.background_video ? (
          this.renderVideoBackground()
        ) : (
          <img
            onError={(e) => {
              e.target.classList.add('broken-img');
              e.target.style.display = 'block';
              this.setState({ imageError: true });
            }}
            onLoad={(e) => {
              e.target.style.display = 'none';
              e.target.classList.remove('broken-img');
              this.setState({ imageError: false });
            }}
            src={this.getBackgroundUrl(content.image)}
            alt=""
          />
        )}
        {!this.state.imageError && (
          <div
            className="element-image-with-text-container"
            style={{
              backgroundImage: content.background_video
                ? 'none'
                : `url(${this.getBackgroundUrl(content.image)})`
            }}
          >
            <div
              className="element-image-with-text-container-opacity"
              style={{
                opacity: content.opacity,
                background: content.opacity_color,
              }}
            ></div>
            <div className="element-image-with-text-container-center">
              <div className="element-image-with-text-container-line">
                <div
                  className="element-image-with-text-container-divider"
                  style={{ background: content.divider_color }}
                ></div>
              </div>
              <div className="element-image-with-text-container-text">
                {this.props.editable ? (
                  <Quill
                    value={content.text}
                    onChange={(text) => this.handleTextChange(text)}
                  />
                ) : (
                  <HtmlString className="editor" value={content.text} />
                )}
              </div>
            </div>
            {content.legend && (
              <div style={this.getLegendStyle()}>{content.legend}</div>
            )}
          </div>
        )}
        {footerActionsTemplate}
      </div>
    );
  }
}
