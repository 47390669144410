import { TablePropertiesAction } from "./table-properties-action";
import { TablePropertiesCell } from "./table-properties-cell";
import { TablePropertiesContainer } from "./table-properties-container";
import { TableProperties as Root } from "./table-properties";

export const TableProperties = {
  Action: TablePropertiesAction,
  Cell: TablePropertiesCell,
  Container: TablePropertiesContainer,
  Table: Root,
};
