import React, { memo } from "react";
import * as S from "./table.style";
import Quill from "../../components/quill/quill";
import { useDebouncedChange } from "./hooks/useDebouncedChange"; // Caminho do hook
import { useResize } from "./hooks/useResize";
import { getFont, getImageUrl } from "./common/utils/utils";
import { useTableContext } from "./context/table-context";
import { useClipboard } from "./context/table-clipboard-context";

const TableEditableCell = ({ handler, col, data, row, onChange }) => {
  const { content } = useTableContext();
  const cell = (row && row.cells && row.cells[col.id]) ? row.cells[col.id] : {};
  const { onSelecting, onEndSelect, onStartSelect, isSelected, setActiveEditor } = useClipboard();
  const initialSize = {
    width: cell && cell.size ? cell.size.width : 300,
    height: cell && cell.size ? cell.size.height : 200
  };
    const cellPos = { column: col.id, row: row.id };

  const [value, setValue] = useDebouncedChange(cell.value, 300, (newValue) => {
    onChange({
      ...cell,
      value: newValue,
    });
  });

  const { size, resize } = useResize(initialSize, (newSize) => {
    onChange({ ...cell, size: newSize });
  });

  const getCellBackground = () => {
    if (cell.background_color) return cell.background_color;
    if (row.background_color) return row.background_color;
    if (col.column_background_color) return col.column_background_color;
    if (handler.cellSelectedPerParents(col, row) || isSelected(cellPos)) return "#daeeff";
    return "#fff";
  };

  const properties = {
    family: col.font_family || data.font_family || row.font_family || cell.font_family,
    color: cell.font_color || col.column_font_color || row.font_color || content.font_color || "#000",
    background: getCellBackground(),
    align: cell.align || col.align,
  };

  const onMouseDown = (e) => {
    if (e.button === 2 && isSelected(cellPos)) {
      return;
    }
    onStartSelect(cellPos);
    handler.handleCellClick(col, row);
  };

  return (
    <S.TableCell
      onContextMenu={(e) => {
        e.preventDefault();
        handler.handleCellClick(col, row);
        handler.popover.onOpen(e);
      }}
      verticalAlign={cell.vertical_align}
      onMouseDown={onMouseDown}
      align={properties.align}
      color={properties.color}
      background={properties.background}
      onMouseEnter={() => {
        handler.handleMouseOverCell(col, data);
        onSelecting(cellPos);
      }}
      onMouseUp={() => {
        onEndSelect();
      }}
      onMouseLeave={handler.handleMouseLeaveCell}
      className={`cell ${getFont(properties.font || "")} ${properties.font ? "font-exception" : ""} ${
        handler.cellSelectedPerParents(col, row) || isSelected(cellPos) ? `cell-selected row-${row.id} col-${col.id}` : ""
      }`}
    >
      <S.TableCellContainer>
        {cell.type === "image" ? (
          <S.Resizable
            onMouseUp={resize.finishResize}
            onMouseDown={resize.startResize}
            style={{ width: size.width || 300, height: size.height || 200 }}
          >
            <S.Image src={getImageUrl(value)} alt="Imagem" />
          </S.Resizable>
        ) : (
          <>
            {handler.cellSelected(col, row) ? (
              <Quill
                onBlur={() => {
                  setActiveEditor(false);
                }}
                onFocus={() => {
                  setActiveEditor(true);
                }}
                value={value || ""}
                onChange={(newValue) => setValue(newValue)}
                suppressChangeUnmount
              />
            ) : (
              <div className="ql-editor ql-fake" dangerouslySetInnerHTML={{ __html: value }} />
            )}
          </>
        )}
      </S.TableCellContainer>
    </S.TableCell>
  );
};

export default memo(TableEditableCell);
