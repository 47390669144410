import React from "react";
import * as S from "./table.style";
import { TableAddActions } from "./components/table-add-actions/table-add-actions";
import { useTableMouseHandlers } from "./hooks/useTableMouseHandlers";
import { useTableActions } from "./hooks/useTableActions";
import { TableEditableHeader } from "./table-editable-header";
import { TableRow } from "./table-row";
import { TableHeaderOptions } from "./table-header-options";
import { TableProperties } from "./components/table-properties";
import { useTableContext } from "./context/table-context";
import { getFont } from "./common/utils/utils";
import { TableClipBoardProvider } from "./context/table-clipboard-context";
import { TablePopover } from "./table-popover";
import { TableOverlaySelect } from "./table-overlay-select";

const ElementTable = ({ className, footerActionsTemplate, headerActionsTemplate }) => {
  const handler = useTableMouseHandlers();
  const actions = useTableActions();
  const { saveCellProperties, setActiveCell, activeCell, content, tableData: data } = useTableContext();

  return (
    <>
      <TableClipBoardProvider handler={handler} actions={actions}>
        <S.Wrapper
          style={{
            background: content.background_color,
            paddingTop: content.padding_top + 'px',
            paddingBottom: content.padding_bottom + 'px',
          }}
          className={`${getFont(content.font_family)} rdp-element-table-1`}
        >
          {headerActionsTemplate}

          <S.TableContainer className="table-container">
           <TableOverlaySelect />
            <S.TableScrolled>
              <S.Table>
                <S.TableHeader>
                  <tr>
                    {data.columns.map((col, i) => (
                      <TableEditableHeader
                        onChange={actions.changeCellColumn}
                        key={col.id}
                        col={col}
                        data={data}
                        handler={handler}
                      />
                    ))}
                  </tr>
                </S.TableHeader>
                <S.TableBody>
                  {data.rows.map((row) => (
                    <TableRow row={row} data={data} handler={handler} key={row.id} onChange={actions.changeCellRow} />
                  ))}
                </S.TableBody>
              </S.Table>
            </S.TableScrolled>

            <S.TableRowAction onClick={actions.addRow}>
              <TableAddActions />
            </S.TableRowAction>

            <S.TableColumnAction
              onClick={actions.addColumn}
              style={{
                ...(handler.hoverCell && {
                  opacity: 1,
                }),
              }}
            >
              <TableAddActions />
            </S.TableColumnAction>
          </S.TableContainer>

          <TableHeaderOptions handler={handler} actions={actions} />
          <TablePopover handler={handler} actions={actions} />

          {footerActionsTemplate}
        </S.Wrapper>

      </TableClipBoardProvider>

      <TableProperties.Container onClose={() => setActiveCell(null)} open={Boolean(activeCell)}>
        <TableProperties.Cell />
        <TableProperties.Action onSave={() => saveCellProperties(handler.selectedCell)} />
      </TableProperties.Container>
    </>
  );
};

export default ElementTable;
