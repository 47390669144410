import $ from 'jquery';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import RDP_CONFIG from '../../config';
import {DamService} from '../../services/dam';
import {UserService} from '../../services/user';
import './element-edit.css';
import './element-edit-dark.css';
import replaceCompositionObjectsIds from "../../utils/replaceCompositionObjectsIds";

import {
  ElementTextProperties,
  ElementTextTwoColumnProperties,
  ElementListProperties,
  ElementImageProperties,
  ElementImageAndTextProperties,
  ElementImageWithTextProperties,
  ElementHead1Properties,
  ElementVideoProperties,
  ElementAudioProperties,
  ElementIframeProperties,
  ElementAccordionProperties,
  ElementTabsProperties,
  ElementFlashcards1Properties,
  ElementFlashcards2Properties,
  ElementQuiz1Properties,
  ElementQuiz2Properties,
  ElementQuiz3Properties,
  ElementQuiz5Properties,
  ElementQuiz6Properties,
  ElementImagesGalleryProperties,
  ElementForum1Properties,
  ElementDivider1Properties,
  ElementDividerNumberedProperties,
  VirtualElementIndex1Properties,
  ElementHotspots1Properties,
  ElementStatement1Properties,
  ElementStatement2Properties,
  ElementStatement3Properties,
  ElementStatement4Properties,
  ElementNote1Properties,
  ElementQuote1Properties,
  ElementQuote2Properties,
  ElementQuote3Properties,
  ElementQuote4Properties,
  ElementQuote5Properties,
  ElementSortedActivity1Properties,
  ElementTimeline1Properties,
  ElementSteps1Properties,
  ElementAttachment1Properties,
  ElementQuote6Properties,
  ElementGallery1Properties,
  ElementCodeSnippet1Properties,
  ElementQuiz4Properties,
  ElementButton1Properties,
  ElementDivider4Properties,
  Element3dProperties
} from '../../elements';
import { getTheme } from '../../utils/getTheme';
import ElementInteractiveVideoProperties from '../../elements/element-interactive-video-properties';
import { StructureProvider } from '../../hooks/context/structure';
import { TableProperties } from '../../elements/element-table/components/table-properties';

export default class ElementEdit extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      "content": this.props.content,
      "render_template_options": false,
      "render_template_new": false,
      "templates": null,
      "template_name": null,
      "saving_template": false,
      "render_template_save_success": false,
      "render_template_save_error": false,
      "render_templates_list": false,
      "applying_template": false,
      "template_search_term": ""
    };
  }
  onError = e => {
    e.target.classList.add('broken-img');
  };

  onLoad = e => {
    e.target.classList.remove('broken-img');
  }

  handleListenerAllImages = (cleanUp) => {
    const wrapper = document.querySelector('.rdp-composer-element-edit');

    if (!wrapper) return;

    const images = wrapper.querySelectorAll('img');

    images.forEach(item => {
      item.onerror = cleanUp ? null : this.onError;
      item.onload = cleanUp ? null : this.onLoad;
    })
  };

  componentDidMount() {
    this.getDAMTemplates();

    this.handleListenerAllImages();
  }

  componentWillUnmount(){
    this.handleListenerAllImages(true)
  }
  
  onChange(type, data) {
    var content = this.state.content;
    this.props.onChange(content);
  }

  onContentChange(content) {
    this.setState({
      "content": content
    })
  }

  renderElementProperties() {
    var content = this.props.content;
    var type = content.type;
    if (type === "text-1" || type === "text-2" || type === "text-3" || type === "text-4" || type === "text-5" || type === "text-6") {
      return <ElementTextProperties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    }
    else if (type === 'table-1') {
      return (
        <TableProperties.Table 
          labels={this.props.labels} 
          content={content} 
          onContentChange={(content) => this.onContentChange(content)} 
        />
      )
    }
    else if (type === "interactive-video") {
      return <ElementInteractiveVideoProperties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } 
    else if (type === "text-two-column") {
      return <ElementTextTwoColumnProperties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "list-1" || type === "list-2" || type === "list-3") {
      return <ElementListProperties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    }  else if (type === "head-1") {
      return <ElementHead1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "image-1" || type === "image-2") {
      return <ElementImageProperties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "image-and-text") {
      return <ElementImageAndTextProperties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "image-with-text") {
      return <ElementImageWithTextProperties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "images-gallery") {
      return <ElementImagesGalleryProperties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "video-1") {
      return <ElementVideoProperties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "audio-1") {
      return <ElementAudioProperties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "iframe") {
      return <ElementIframeProperties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "accordion") {
      return <ElementAccordionProperties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "tabs") {
      return <ElementTabsProperties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "flashcards-1") {
      return <ElementFlashcards1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "flashcards-2") {
      return <ElementFlashcards2Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "quiz-1") {
      return <ElementQuiz1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "quiz-2") {
      return <ElementQuiz2Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "quiz-3") {
      return <ElementQuiz3Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "quiz-4") {
      return <ElementQuiz4Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "quiz-5") {
      return <ElementQuiz5Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "quiz-6") {
      return <ElementQuiz6Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "forum-1") {
      return <ElementForum1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "virtual-index-1") {
      return <VirtualElementIndex1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "hotspots-1") {
      return <ElementHotspots1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "divider-1") {
      return <ElementDivider1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "divider-4") {
      return <ElementDivider4Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "divider-numbered") {
      return <ElementDividerNumberedProperties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "sorted-activity-1") {
      return <ElementSortedActivity1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "statement-1") {
      return <ElementStatement1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "statement-2") {
      return <ElementStatement2Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "statement-3") {
      return <ElementStatement3Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "statement-4") {
      return <ElementStatement4Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "note-1") {
      return <ElementNote1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "quote-1") {
      return <ElementQuote1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "quote-2") {
      return <ElementQuote2Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "quote-3") {
      return <ElementQuote3Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "quote-4") {
      return <ElementQuote4Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "quote-5") {
      return <ElementQuote5Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "quote-6") {
      return <ElementQuote6Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "timeline-1") {
      return <ElementTimeline1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "steps-1") {
      return <ElementSteps1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "attachment-1") {
      return <ElementAttachment1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "gallery-1") {
      return <ElementGallery1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "button-1") {
      return <ElementButton1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "button-2") {
      return <ElementButton1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "snippet-1") {
      return <ElementCodeSnippet1Properties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    } else if (type === "3d") {
      return <Element3dProperties
        content={content}
        onContentChange={(content) => this.onContentChange(content)}
        labels={this.props.labels}
      />
    }
  }

  getTemplateFileType() {
    var fileType = null;
    var type = this.state.content.type;
    if (type === "text-1") {
      fileType = "rdpcomposer_template_element_text_1"
    } else if (type === "text-2") {
      fileType = "rdpcomposer_template_element_text_2"
    } else if (type === "text-3") {
      fileType = "rdpcomposer_template_element_text_3"
    } else if (type === "text-4") {
      fileType = "rdpcomposer_template_element_text_4"
    } else if (type === "text-5") {
      fileType = "rdpcomposer_template_element_text_5"
    } else if (type === "text-6") {
      fileType = "rdpcomposer_template_element_text_6"
    } else if (type === "text-two-column") {
      fileType = "rdpcomposer_template_element_text_two_column"
    } else if (type === "head-1") {
      fileType = "rdpcomposer_template_element_head_1"
    } else if (type === "image-1") {
      fileType = "rdpcomposer_template_element_image_1"
    } else if (type === "image-2") {
      fileType = "rdpcomposer_template_element_image_2"
    } else if (type === "image-and-text") {
      fileType = "rdpcomposer_template_element_image_and_text"
    } else if (type === "image-with-text") {
      fileType = "rdpcomposer_template_element_image_with_text"
    } else if (type === "list-1") {
      fileType = "rdpcomposer_template_element_list_1"
    } else if (type === "list-2") {
      fileType = "rdpcomposer_template_element_list_2"
    } else if (type === "list-3") {
      fileType = "rdpcomposer_template_element_list_3"
    } else if (type === "video-1") {
      fileType = "rdpcomposer_template_element_video_1"
    } else if (type === "audio-1") {
      fileType = "rdpcomposer_template_element_audio_1"
    } else if (type === "iframe") {
      fileType = "rdpcomposer_template_element_iframe"
    } else if (type === "accordion") {
      fileType = "rdpcomposer_template_element_accordion"
    } else if (type === "tabs") {
      fileType = "rdpcomposer_template_element_tabs"
    } else if (type === "flashcards-1") {
      fileType = "rdpcomposer_template_element_flashcards_1"
    } else if (type === "flashcards-2") {
      fileType = "rdpcomposer_template_element_flashcards_2"
    } else if (type === "quiz-1") {
      fileType = "rdpcomposer_template_element_quiz_1"
    } else if (type === "quiz-2") {
      fileType = "rdpcomposer_template_element_quiz_2"
    } else if (type === "quiz-3") {
      fileType = "rdpcomposer_template_element_quiz_3"
    } else if (type === "quiz-5") {
      fileType = "rdpcomposer_template_element_quiz_5"
    } else if (type === "forum-1") {
      fileType = "rdpcomposer_template_element_forum_1"
    } else if (type === "hotspots-1") {
      fileType = "rdpcomposer_template_element_hotspots_1"
    } else if (type === "divider-1") {
      fileType = "rdpcomposer_template_element_divider_1"
    } else if (type === "divider-4") {
      fileType = "rdpcomposer_template_element_divider_4"
    } else if (type === "divider-numbered") {
      fileType = "rdpcomposer_template_element_divider_numbered"
    } else if (type === "statement-1") {
      fileType = "rdpcomposer_template_element_statement_1"
    } else if (type === "statement-2") {
      fileType = "rdpcomposer_template_element_statement_2"
    } else if (type === "statement-3") {
      fileType = "rdpcomposer_template_element_statement_3"
    } else if (type === "statement-4") {
      fileType = "rdpcomposer_template_element_statement_4"
    } else if (type === "note-1") {
      fileType = "rdpcomposer_template_element_note_1"
    } else if (type === "quote-1") {
      fileType = "rdpcomposer_template_element_quote_1"
    } else if (type === "quote-2") {
      fileType = "rdpcomposer_template_element_quote_2"
    } else if (type === "quote-3") {
      fileType = "rdpcomposer_template_element_quote_3"
    } else if (type === "quote-4") {
      fileType = "rdpcomposer_template_element_quote_4"
    } else if (type === "quote-5") {
      fileType = "rdpcomposer_template_element_quote_5"
    } else if (type === "quote-6") {
      fileType = "rdpcomposer_template_element_quote_6"
    } else if (type === "timeline-1") {
      fileType = "rdpcomposer_template_element_timeline_1"
    } else if (type === "steps-1") {
      fileType = "rdpcomposer_template_element_steps_1"
    } else if (type === "attachment-1") {
      fileType = "rdpcomposer_template_element_attachment_1"
    } else if (type === "gallery-1") {
      fileType = "rdpcomposer_template_element_gallery_1"
    } else if (type === "snippet-1") {
      fileType = "rdpcomposer_template_element_snippet_1"
    } else if (type === "3d") {
      fileType = "rdpcomposer_template_element_3d"
    } else if (type === 'accordion') {
      fileType = "rdpcomposer_template_element_accordion"
    }

    return fileType;
  }

  saveProperties() {
    this.props.onChange(this.state.content);
  }

  toggleTemplateOptions() {
    this.setState({
      "render_template_options": !this.state.render_template_options
    });
  }

  openTemplateNew() {
    this.setState({
      "render_template_options": false,
      "render_template_new": true
    });
  }

  closeTemplateNew() {
    this.setState({
      "render_template_options": false,
      "render_template_new": false
    });
  }

  onChangeTemplateName(e) {
    this.setState({
      "template_name": e.target.value
    });
  }

  onClickSaveTemplate() {
    this.setState({
      "saving_template": true
    })

    this.saveTemplate()
  }

  blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  getTemplateFile() {
    var myBlob = new Blob([JSON.stringify(this.props.content)], { type: `application/${this.getTemplateFileType()}` });
    return this.blobToFile(myBlob, `${this.state.template_name}.${this.getTemplateFileType()}`);
  }

  saveTemplate() {
    var _this = this;
    var connectorUid = JSON.parse(window.localStorage.getItem("rdp-composer-active-project")).connectors[0].uid;
    var fd = new FormData();
    var files = this.getTemplateFile();
    var userData = JSON.parse(window.localStorage.getItem("rdp-composer-user-data"));
    var type = userData.type;
    var accessToken = userData.access_token;

    fd.append('file', files, `${this.state.template_name}.${this.getTemplateFileType()}`)

    $.ajax({
      url: `https://${RDP_CONFIG.domain}/storage/v1.0/upload/connector/uid/${connectorUid}`,
      type: "POST",
      data: fd,
      contentType: false,
      processData: false,
      headers: {
        "Authorization": type + " " + accessToken,
        "Project-Key" : RDP_CONFIG.projectKey,
        "Api-Env" : RDP_CONFIG.apiEnv
      },
      success: function (response) {
        var uid = response[0].document.content_uid;
        var contentTags = [];
        var contentFilters = {};
        var contentInfo = {};
        contentInfo.file_name = _this.state.template_name;

        DamService.updateFile(contentTags, contentInfo, contentFilters, uid, type, accessToken).then(function (data) {
          console.log(data)
        });

        var newId = response[0].uid;
        _this.setState({
          "saving_template": false,
          "render_template_new": false,
          "render_template_save_success": true
        });

        setTimeout(function() {
          _this.onAlertSuccessClose()
        }, 3000)
      },
      error: function (response) {
        _this.setState({
          "saving_template": false,
          "render_template_new": false,
          "render_template_save_error": true
        });

        setTimeout(function() {
          _this.onAlertErrorClose()
        }, 3000)
      }
    });
  }

  onAlertSuccessClose() {
    this.setState({
      "render_template_save_success": false
    })
  }

  onAlertErrorClose() {
    this.setState({
      "render_template_save_error": false
    })
  }

  openTemplatesList() {
    this.getDAMTemplates();

    this.setState({
      "render_template_options": false,
      "render_templates_list": true
    });
  }

  closeTemplatesList() {
    this.setState({
      "templates": null,
      "render_template_options": false,
      "render_templates_list": false
    });
  }

  getDAMTemplates() {
    var _this = this;
    var userData = UserService.getUser();
    var activeProject = UserService.getActiveProject();
    var type = userData.type;
    var accessToken = userData.access_token;
    var filter = `filter=(content_type eq 'application/${this.getTemplateFileType()}') and (project_uid eq '${activeProject.uid}')&top=1000`;

    DamService.searchContent(filter, "", type, accessToken).then(function(data) {
      _this.setState({
        "templates": data.results
      })
    });
  }

  applyTemplate(template) {
    var _this = this;
    var id = template.document.content_uid;

    _this.setState({
      "applying_template": true
    });

    var userData = UserService.getUser();
    var accessToken = userData.access_token;
    DamService.getContent(id, accessToken).then(function (template) {
      _this.props.onChange(_this.normalizeTemplateObject(JSON.parse(template)));
    });
  }

  normalizeTemplateObject(template) {
    var obj = JSON.parse(JSON.stringify(template));

    obj = replaceCompositionObjectsIds({...obj});
    
    obj.id = this.props.content.id;
    return obj;
  }

  onChangeTemplateSearchTerm(e) {
    this.setState({
      "template_search_term": e.target.value
    });
  }

  getFilteredTemplates() {
    var templates = this.state.templates;
    var term = this.state.template_search_term;

    if (term !== "") {
      templates = templates.filter((item) => 
        item.document.content_info[0] && 
          item.document.content_info[0].split("file_name=")[1] &&
            item.document.content_info[0].split("file_name=")[1].toLowerCase().search(term.toLowerCase()) > -1)
    }

    return templates;
  }

  render() {
    var _this = this;

    return (
      <div className={`rdp-composer-element-edit rdp-composer-element-edit-theme-${getTheme()}`}>
        <div className="rdp-composer-element-edit-overlay" onClick={() => this.props.onClose()}></div>
        <div className={`rdp-composer-element-edit-modal rdp-composer-element-edit-modal-theme-${getTheme()}`}>
          {this.state.render_template_save_success &&
            <div className="rdp-composer-element-edit-alert success" onClick={() => this.onAlertSuccessClose()}>
              {this.props.labels.elements.common_settings.label_template_success_alert}
              <span className="rdp-composer-element-edit-alert-close"></span>
            </div>
          }

          {this.state.render_template_save_error &&
            <div className="rdp-composer-element-edit-alert success" onClick={() => this.onAlertErrorClose()}>
              {this.props.labels.elements.common_settings.label_template_error_alert}
              <span className="rdp-composer-element-edit-alert-close"></span>
            </div>
          }

          <div className="rdp-composer-element-edit-header">
            <span className="rdp-composer-element-edit-header-title">{this.props.labels.elements.common_settings.title}</span>
            <div className="rdp-composer-element-edit-header-template-picker">
              <div className="rdp-composer-element-edit-header-template-picker-btn" onClick={() => this.toggleTemplateOptions()}>
                <span className="rdp-composer-element-edit-header-template-picker-btn-label">{this.props.labels.elements.common_settings.label_template_manager_templates}</span>
                <span className="rdp-composer-element-edit-header-template-picker-btn-info" data-for="template-info-tooltip" data-tip={this.props.labels.elements.common_settings.label_template_instructions}></span>
                <ReactTooltip id='template-info-tooltip' className="rdp-tooltip" multiline={true} place="bottom" type="dark" backgroundColor="#2d7b45"/>
              </div>
              {this.state.render_template_options &&
                <ul className="rdp-composer-element-edit-header-template-picker-options">
                  <li className="rdp-composer-element-edit-header-template-picker-option" onClick={() => this.openTemplateNew()}>{this.props.labels.elements.common_settings.label_template_save_new_template}</li>
                  <li className="rdp-composer-element-edit-header-template-picker-option" onClick={() => this.openTemplatesList()}>{this.props.labels.elements.common_settings.label_template_use_existent_template}</li>
                </ul>
              }
            </div>
            <span className="rdp-composer-element-edit-header-close" onClick={() => this.props.onClose()}></span>
          </div>
          <StructureProvider 
            element={this.props.content} 
            onContentChange={newElement => this.onContentChange(newElement)} 
            structure={this.props.structure}
          >
            {this.renderElementProperties()}
          </StructureProvider>
          <div className="rdp-composer-element-edit-footer">
            <button className="rdp-composer-element-edit-submit" onClick={() => this.saveProperties()}>{this.props.labels.elements.common_settings.label_btn_save}</button>
          </div>

          {this.state.render_template_new &&
            <div className="rdp-composer-element-edit-template-new">
              <div className="rdp-composer-element-edit-template-new-modal">
                <div className="rdp-composer-element-edit-template-new-modal-header">
                  <span className="rdp-composer-element-edit-template-new-modal-header-title">{this.props.labels.elements.common_settings.label_template_save_template}</span>
                  <span className="rdp-composer-element-edit-template-new-modal-header-close" onClick={() => this.closeTemplateNew()}></span>
                </div>
                <div className="rdp-composer-element-edit-template-new-modal-body">
                  <span className="rdp-composer-element-edit-template-new-modal-body-subtitle">{this.props.labels.elements.common_settings.label_template_instructions_template_name}</span>
                  <input className="rdp-composer-element-edit-template-new-modal-body-input" type="text" onChange={(e) => this.onChangeTemplateName(e)}></input>
                </div>
                <div className="rdp-composer-element-edit-template-new-modal-footer">
                  <button className="rdp-composer-element-edit-template-new-modal-cancel" onClick={() => this.closeTemplateNew()}>{this.props.labels.elements.common_settings.label_template_cancel}</button>
                  <button className={`rdp-composer-element-edit-template-new-modal-submit ${(this.state.template_name !== null && this.state.template_name !== "") ? "active" : ""}`} onClick={() => this.onClickSaveTemplate()}>{this.props.labels.elements.common_settings.label_template_save}</button>
                </div>
                {this.state.saving_template &&
                  <div className="rdp-composer-element-edit-template-new-modal-preloader"></div>
                }
              </div>
            </div>
          }

          {this.state.render_templates_list &&
            <div className="rdp-composer-element-edit-templates-list">
              <div className="rdp-composer-element-edit-templates-list-modal">
                <div className="rdp-composer-element-edit-templates-list-modal-header">
                  <span className="rdp-composer-element-edit-templates-list-modal-header-title">{this.props.labels.elements.common_settings.label_template_use_template}</span>
                  <span className="rdp-composer-element-edit-templates-list-modal-header-close" onClick={() => this.closeTemplatesList()}></span>
                </div>
                <div className="rdp-composer-element-edit-templates-list-modal-search">
                  <input className="rdp-composer-element-edit-templates-list-modal-search-field" type="text" placeholder={this.props.labels.elements.common_settings.label_template_search_template} onChange={(e) => this.onChangeTemplateSearchTerm(e)}/>
                </div>
                {this.state.templates && this.state.templates.length > 0 &&
                  <ul className="rdp-composer-element-edit-templates-list-modal-items">
                    {this.state.templates && this.getFilteredTemplates().map(function (template, index) {
                      return <li key={index} className="rdp-composer-element-edit-templates-list-modal-item" onClick={() => _this.applyTemplate(template)}>
                        <span className="rdp-composer-element-edit-templates-list-modal-item-label">{template && template.document && template.document.content_info[0] && template.document.content_info[0].split("file_name=") && template.document.content_info[0].split("file_name=")[1]}</span>
                        <span className="rdp-composer-element-edit-templates-list-modal-item-icon-select"></span>
                      </li>
                    })}
                  </ul>
                }
                {this.state.templates && this.state.templates.length === 0 &&
                  <div className="rdp-composer-element-edit-templates-list-modal-empty">
                    <div className="rdp-composer-element-edit-templates-list-modal-empty-content">
                      <span className="rdp-composer-element-edit-templates-list-modal-empty-icon"></span>
                      <span className="rdp-composer-element-edit-templates-list-modal-empty-label">{this.props.labels.elements.common_settings.label_template_empty_templates}</span>
                    </div>
                  </div>
                }
                {this.state.templates === null &&
                  <div className="rdp-composer-element-edit-templates-list-modal-preloader"></div>
                }
                {this.state.applying_template &&
                  <div className="rdp-composer-element-edit-templates-list-modal-applying-preloader"></div>
                }
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}
