import uuidv1 from "uuid/v1";

/**
 * util de criação de tabela. Veja o padrão de json esperado após a criação: 
 * @var data = {
      columns: [
        { id: "col1", header: "A" },
      ],
      rows: [
        {
          id: "row1",
          cells: { col1: "Cell 1A", col2: "Cell 1B", col3: "Cell 1C" },
        },
      ],
    },
 */

const COLS_LENGTH = 3;
const ROWS_LENGTH = 3;

export const createTextCell = (text = " ", color = "#000") => {
  return `<p><span style="font-size: 18px; color: ${color}; line-height: 26px;">${text}</span></p>`;
};

export const createCol = () => {
  return { id: uuidv1(), header: createTextCell(" ", "#fff") };
};

const patternText = createTextCell();

export const createCell = () => ({
  type: "text",
  value: patternText,
})

export const createRow = (columns) => {
  let cells = {};

  columns.forEach((col) => {
    cells[col.id] = createCell()
  });

  return {
    id: uuidv1(),
    cells,
  };
};

export const createObjectTable = () => {
  const columns = Array.from({ length: COLS_LENGTH }).map(createCol);
  const rows = Array.from({ length: ROWS_LENGTH }).map(() => createRow(columns));

  return {
    data: {
      columns,
      rows,
    },
  };
};
