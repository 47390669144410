import { useEffect, useState } from "react";
import { useClipboard } from "../context/table-clipboard-context";

export const useOverlay = () => {
  const { onEndSelect, onSelecting, selectedList, setSelectedList, isSelected, selectedRowList, selectedColumns } =
    useClipboard();
  const [overlayStyles, setOverlayStyles] = useState({ width: 0, height: 0 });

  const getCellListFromRowsSelecteds = () => {
    const selectedRows = selectedRowList;
    if (selectedRows.length) {
      const cellsNodeRows = [];
      selectedRows.forEach((row) => {
        const cells = Array.from(document.querySelectorAll(`.row-${row}`));
        cells.forEach((cell) => {
          const setted = cellsNodeRows.some((nodeRow) => nodeRow.classList.contains(`row-${row}`));
          if (!setted) {
            cellsNodeRows.push(cell);
          }
        });
      });
      return cellsNodeRows;
    }
    return [];
  };

  const getCellListFromColumnsSelecteds = () => {
    if (selectedColumns.length) {
      const cellsNodeColumns = [];
      selectedColumns.forEach((col) => {
        const cells = Array.from(document.querySelectorAll(`.col-${col}`));
        cells.forEach((cell) => {
          const setted = cellsNodeColumns.some((nodeCol) => nodeCol.classList.contains(`col-${col}`));
          if (!setted) {
            cellsNodeColumns.push(cell);
          }
        });
      });
      return cellsNodeColumns;
    }
    return [];
  };

  const getDimenstionsFromCalcOfselectedCells = () => {
    const cellFromRows = getCellListFromRowsSelecteds();
    const cellFromColumns = getCellListFromColumnsSelecteds();
    if (!cellFromRows.length || !cellFromColumns.length) return;

    const firstCell = cellFromRows[0];  // Pegue a primeira célula selecionada
    const tableContainer = document.querySelector('.table-container');  // Contêiner da tabela
    
    const firstCellRect = firstCell.getBoundingClientRect();  // Pega as coordenadas da célula
    const containerRect = tableContainer.getBoundingClientRect();

    const top = firstCellRect.top - containerRect.top + tableContainer.scrollTop;
    const left = firstCellRect.left - containerRect.left + tableContainer.scrollLeft;

    const width = cellFromColumns.reduce((totalWidth, currentNode) => totalWidth + currentNode.clientWidth, 0);
    const height = cellFromRows.reduce((totalHeight, currentNode) => totalHeight + currentNode.clientHeight, 0);

    setOverlayStyles((prev) => ({
      ...prev,
      width,
      height,
      top, // Para lidar com o scroll vertical da página
      left,
    }));
  };

  useEffect(() => {
    getDimenstionsFromCalcOfselectedCells();
  }, [selectedList]);

  return {
    overlayStyles,
  };
};
