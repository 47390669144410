import React, { Component } from 'react';
import { Quill } from '../../components';
import {UserService} from '../../services/user';
import './element-list.css';

export default class ElementList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "content": this.props.content,
      "active_items": []
    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.content !== prevProps.content) {
      this.setState({ content: this.props.content });
    }
  };

  handleChange(text, index) {
    //console.log(text)
    var content = this.state.content;
    content.items[index].text = text;
    this.setState({
      "content": content
    });
    this.props.onChange(content);
  }

  getImageUrl(image) {
    var accessToken = UserService.getUser().access_token;
    if (image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-1/image-1.jpg" &&
    image !== "https://pocs.digitalpages.com.br/rdpcomposer/media/image-2/image-2.jpg") {
      image += "&access_token=" + accessToken;
    };
    return image;
  }

  renderQuill(item, index) {
    if (this.props.editable) {
      return <>
        <Quill
          value={item.text}
          onChange={(text) => this.handleChange(text, index)}
        />
      </>
    } else {
      return <div className="editor" dangerouslySetInnerHTML={{__html: item.text}}></div>
    }
  }

  getFont(font) {
    if (font) return font.replace(/\s/g, '-').toLowerCase();
  }

  onItemCheck(id) {
    if (!this.isItemActive(id)) {
      var items = this.state.active_items;
      items.push(id);
      this.setState({
        "items": items
      });
    }
  }

  isItemActive(id) {
    var items = this.state.active_items;
    return items.filter(item => item === id).length > 0;
  }

  render() {
    var _this = this;
    let { headerActionsTemplate, footerActionsTemplate, content } = this.props;
    var listStyle = {
      background: content.background_color,
      paddingTop: content.padding_top + "px",
      paddingBottom: content.padding_bottom + "px"
    }
    var items = this.state.content.items;

    return <div className={`${this.props.className} ${this.getFont(content.font_family) || ""}`} style={listStyle}>
      {headerActionsTemplate}
      <ul className="element-list-items">
        {items.map(function(item, index) {
          return <li key={index} className="element-list-item">
            {content.type === "list-1" &&
              <span className="element-list-item-number" style={{
                background: content.primary_color,
                color: content.secondary_color
              }}>{index + 1}</span>
            }
            {content.type === "list-2" &&
              <span className="element-list-item-checkbox"
                style={{
                  borderColor: content.primary_color,
                  color: content.secondary_color
                }}
                onClick={() => _this.onItemCheck(item.id)}
              >
                {_this.isItemActive(item.id) &&
                  <span className="element-list-item-checkbox-checked" style={{background: content.primary_color}}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="768" height="768" viewBox="0 0 768 768">
                      <path fill={content.secondary_color} d="M617.376 169.376l-329.376 329.376-137.376-137.376c-12.512-12.512-32.768-12.512-45.248 0s-12.512 32.768 0 45.248l160 160c12.512 12.512 32.768 12.512 45.248 0l352-352c12.512-12.512 12.512-32.768 0-45.248s-32.768-12.512-45.248 0z"></path>
                    </svg>
                  </span>
                }
              </span>
            }
            {content.type === "list-3" &&
              <span className="element-list-item-bullet" style={{background: content.primary_color}}></span>
            }
            <div className="element-list-item-text">
              {_this.renderQuill(item, index)}
            </div>
          </li>
        })}
      </ul>
      {footerActionsTemplate}
    </div>
  }
}
