import React, { createContext, useContext, useEffect, useMemo, useState } from "react";

export const TableContext = createContext(null);

export const TableProvider = ({ children, content, onChange, labels }) => {
  const [activeCell, setActiveCell] = useState(null);

  const handleChangeData = (params) => {
    onChange({
      ...content,
      table: {
        ...content.table,
        ...params,
      },
    });
  };

  const saveCellProperties = (cellPosition) => {
    if (!activeCell) return;
    const rows = [...content.table.data.rows];

    rows.forEach((row) => {
      if (row.id === cellPosition.row) {
        if (row.cells[cellPosition.column]) {
          row.cells[cellPosition.column] = activeCell;
        }
      }
    });

    handleChangeData({
      data: {
        ...content.table.data,
        rows,
      },
    });

    setActiveCell(false);
  };

  const memoizedContextValues = useMemo(
    () => ({
      content,
      tableData: content.table.data,
      onChange: handleChangeData,
      setActiveCell,
      activeCell,
      labels,
      saveCellProperties,
    }),
    [content, activeCell]
  );

  return <TableContext.Provider value={memoizedContextValues}>{children}</TableContext.Provider>;
};

export const useTableContext = () => {
  try {
    const context = useContext(TableContext);
    return context;
  } catch (err) {
    console.log("useTableContext should be used inside TableProvider", err);
  }
};
