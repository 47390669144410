import React, { Component } from 'react'
import './sidebar.css';
import {
  SidebarBtn,
  SidebarSubitem
} from '../';
import RDP_CONFIG from '../../config';
import { getTheme } from '../../utils/getTheme';
import ReactTooltip from 'react-tooltip';

export default class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "subitems": "none",
      "theme": getTheme(),
      "imageListWithErrors": [],
      "previousError": false,
    }

    this.renderSubitems = this.renderSubitems.bind(this);
    this.closeSubitems = this.closeSubitems.bind(this);
    this.onClickSubitem = this.onClickSubitem.bind(this);
    this.openPreview = this.openPreview.bind(this)
  }

  checkCompositionImagesWithError() {
    const brokens = document.querySelectorAll('.broken-img');
    const absoluteBrokens = document.querySelectorAll('.broken-img-absolute');
    const imagesWithFailure = [
      ...Array.from(brokens),
      ...Array.from(absoluteBrokens)
    ];
    
    if (imagesWithFailure.length !== this.state.imageListWithErrors.length) {
      this.setState({ imageListWithErrors: imagesWithFailure });
    }
  };

  handleClickShowImagesWithError() {
    const [element] = this.state.imageListWithErrors;

    element.scrollIntoView();
  };

  componentDidUpdate() {
    this.checkCompositionImagesWithError();

    if (!this.state.previousError && this.state.imageListWithErrors.length) {
      this.setState({ previousError: true })
    }
  }

  renderSubitems(type) {
    this.setState({
      subitems: type
    });
  }

  closeSubitems() {
    this.setState({
      subitems: "none"
    })
  }

  onClickSubitem(type) {
    this.closeSubitems();
    this.props.onClickSubitem(type);
  }

  openPreview() {
    window.location.href = window.location.href + "/preview";
  }


  renderMenu() {
    if (this.props.openedMenu === true) {
      return <div className="rdp-composer-sidebar-menu-container">
        <div className="rdp-composer-sidebar-menu-container-overlay" onClick={() => this.props.onToggleMenu()}></div>
        <ul className="rdp-composer-sidebar-menu-container-popup">
          <li className="rdp-composer-sidebar-menu-container-popup-item save" onClick={() => this.props.onSaveClick()}>{this.props.labels.header.menu_save_composition}</li>
          <li className="rdp-composer-sidebar-menu-container-popup-item metadata" onClick={() => this.props.onConfigurationClick()}>{this.props.labels.header.menu_settings}</li>
          <li className="rdp-composer-sidebar-menu-container-popup-item history" onClick={() => this.props.onVersionsHistoryClick()}>{this.props.labels.header.menu_versions_history}</li>
          {(typeof this.props.onZipExport === "function") &&
            <>
              {this.state.imageListWithErrors.length > 0 ? (
                <>
                  <ReactTooltip
                  id="export-zip-error"
                  className="rdp-tooltip"
                  multiline={true}
                  place="bottom"
                  type="dark"
                  backgroundColor="#1b1b1bda"
                  />
                  <li
                    data-for="export-zip-error"
                    data-tip={
                      `Sua composição está com arquivos indisponíveis. Clique aqui para verificar os objetos e realizar as alterações necessárias.`}
                    className="rdp-composer-sidebar-menu-container-popup-item zip-error"
                    onClick={() => this.handleClickShowImagesWithError()}
                    >
                    
                    {this.props.labels.header.menu_export_zip}
                  </li>
                </>  
              ) : (
                <li
                  className="rdp-composer-sidebar-menu-container-popup-item zip"
                  onClick={() => this.props.onZipExport(this.state.previousError)}
                >
                  {this.props.labels.header.menu_export_zip}
                </li>
              )}
            </>
          }
          <li className={`rdp-composer-sidebar-menu-container-popup-item preview ${this.props.previewEnabled === false ? "disabled" : ""}`} onClick={() => this.props.onPreviewClick()}>{this.props.labels.header.menu_preview}</li>
        </ul>
      </div>;
    };
  }

  render() {
    var subitems = "";
    if (this.state.subitems === "header") {
      subitems = <div>
        <div className={`rdp-composer-sidebar-subitems rdp-composer-sidebar-subitems-theme-${this.state.theme}`}>
          <SidebarSubitem
            type="head-1"
            text={this.props.labels.header.elements_descriptions.element_head_1}
            click={() => this.onClickSubitem("head-1")}
          />
          <SidebarSubitem
            type="quote-1"
            text={this.props.labels.header.elements_descriptions.element_quote_1}
            click={() => this.onClickSubitem("quote-1")}
          />
          <SidebarSubitem
            type="quote-2"
            text={this.props.labels.header.elements_descriptions.element_quote_2}
            click={() => this.onClickSubitem("quote-2")}
          />
          <SidebarSubitem
            type="quote-3"
            text={this.props.labels.header.elements_descriptions.element_quote_3}
            click={() => this.onClickSubitem("quote-3")}
          />
          <SidebarSubitem
            type="quote-4"
            text={this.props.labels.header.elements_descriptions.element_quote_4}
            click={() => this.onClickSubitem("quote-4")}
          />
          <SidebarSubitem
            type="quote-5"
            text={this.props.labels.header.elements_descriptions.element_quote_5}
            click={() => this.onClickSubitem("quote-5")}
          />
          <SidebarSubitem
            type="quote-6"
            text={this.props.labels.header.elements_descriptions.element_quote_6}
            click={() => this.onClickSubitem("quote-6")}
          />
        </div>
        <div className="rdp-composer-sidebar-overlay" onClick={() => this.closeSubitems()}></div>
      </div>;
    } else if (this.state.subitems === "text") {
      subitems = <div>
        <div className="rdp-composer-sidebar-subitems">
          <SidebarSubitem
            type="text-1"
            text={this.props.labels.header.elements_descriptions.element_text_1}
            click={() => this.onClickSubitem("text-1")}
          />
          <SidebarSubitem
            type="text-2"
            text={this.props.labels.header.elements_descriptions.element_text_2}
            click={() => this.onClickSubitem("text-2")}
          />
          <SidebarSubitem
            type="text-3"
            text={this.props.labels.header.elements_descriptions.element_text_3}
            click={() => this.onClickSubitem("text-3")}
          />
          <SidebarSubitem
            type="text-4"
            text={this.props.labels.header.elements_descriptions.element_text_4}
            click={() => this.onClickSubitem("text-4")}
          />
          <SidebarSubitem
            type="text-5"
            text={this.props.labels.header.elements_descriptions.element_text_5}
            click={() => this.onClickSubitem("text-5")}
          />
          <SidebarSubitem
            type="text-two-column"
            text={this.props.labels.header.elements_descriptions.element_text_two_column}
            click={() => this.onClickSubitem("text-two-column")}
          />
          <SidebarSubitem
            type="statement-1"
            text={this.props.labels.header.elements_descriptions.element_statement_1}
            click={() => this.onClickSubitem("statement-1")}
          />
          <SidebarSubitem
            type="statement-2"
            text={this.props.labels.header.elements_descriptions.element_statement_2}
            click={() => this.onClickSubitem("statement-2")}
          />
          <SidebarSubitem
            type="statement-3"
            text={this.props.labels.header.elements_descriptions.element_statement_3}
            click={() => this.onClickSubitem("statement-3")}
          />
          <SidebarSubitem
            type="statement-4"
            text={this.props.labels.header.elements_descriptions.element_statement_4}
            click={() => this.onClickSubitem("statement-4")}
          />
          <SidebarSubitem
            type="note-1"
            text={this.props.labels.header.elements_descriptions.element_note_1}
            click={() => this.onClickSubitem("note-1")}
          />
          <SidebarSubitem type="table-1" click={() => this.onClickSubitem("table-1")} text="Tabela personalizada com diversos recursos de edição"/>
          {/* <SidebarSubitem type="text-6" click={() => this.onClickSubitem("text-6")}/> */}
          {/* <SidebarSubitem type="text-7" click={() => this.onClickSubitem("text-7")}/> */}
        </div>
        <div className="rdp-composer-sidebar-overlay" onClick={() => this.closeSubitems()}></div>
      </div>;
    } else if (this.state.subitems === "quote") {
      subitems = <div>
        <div className="rdp-composer-sidebar-subitems">
          <SidebarSubitem type="quote-1" click={() => this.onClickSubitem("quote-1")} />
          <SidebarSubitem type="quote-2" click={() => this.onClickSubitem("quote-2")} />
          <SidebarSubitem type="quote-3" click={() => this.onClickSubitem("quote-3")} />
          <SidebarSubitem type="quote-4" click={() => this.onClickSubitem("quote-4")} />
          <SidebarSubitem type="quote-6" click={() => this.onClickSubitem("quote-6")} />
        </div>
        <div className="rdp-composer-sidebar-overlay" onClick={() => this.closeSubitems()}></div>
      </div>;
    } else if (this.state.subitems === "list") {
      subitems = <div>
        <div className="rdp-composer-sidebar-subitems">
          <SidebarSubitem
            type="list-1"
            text={this.props.labels.header.elements_descriptions.element_list_1}
            click={() => this.onClickSubitem("list-1")}
          />
          <SidebarSubitem
            type="list-2"
            text={this.props.labels.header.elements_descriptions.element_list_2}
            click={() => this.onClickSubitem("list-2")}
          />
          <SidebarSubitem
            type="list-3"
            text={this.props.labels.header.elements_descriptions.element_list_3}
            click={() => this.onClickSubitem("list-3")}
          />
        </div>
        <div className="rdp-composer-sidebar-overlay" onClick={() => this.closeSubitems()}></div>
      </div>;
    } else if (this.state.subitems === "image") {
      subitems = <div>
        <div className="rdp-composer-sidebar-subitems">
          <SidebarSubitem
            type="image-1"
            text={this.props.labels.header.elements_descriptions.element_image_1}
            click={() => this.onClickSubitem("image-1")}
          />
          <SidebarSubitem
            type="image-2"
            text={this.props.labels.header.elements_descriptions.element_image_2}
            click={() => this.onClickSubitem("image-2")}
          />
          <SidebarSubitem
            type="images-gallery"
            text={this.props.labels.header.elements_descriptions.element_images_gallery}
            click={() => this.onClickSubitem("images-gallery")}
          />
          <SidebarSubitem
            type="image-and-text"
            text={this.props.labels.header.elements_descriptions.element_image_and_text}
            click={() => this.onClickSubitem("image-and-text")}
          />
          <SidebarSubitem
            type="image-with-text"
            text={this.props.labels.header.elements_descriptions.element_image_with_text}
            click={() => this.onClickSubitem("image-with-text")}
          />
          {/* <SidebarSubitem type="image-3" click={() => this.onClickSubitem("image-3")}/> */}
          {/* <SidebarSubitem type="image-4" click={() => this.onClickSubitem("image-4")}/> */}
        </div>
        <div className="rdp-composer-sidebar-overlay" onClick={() => this.closeSubitems()}></div>
      </div>;
    } else if (this.state.subitems === "gallery") {
      subitems = <div>
        <div className="rdp-composer-sidebar-subitems">
          <SidebarSubitem type="gallery-1" click={() => this.onClickSubitem("gallery-1")} />
          {/*<SidebarSubitem type="gallery-2" click={() => this.onClickSubitem("gallery-2")} />*/}
          {/*<SidebarSubitem type="gallery-3" click={() => this.onClickSubitem("gallery-3")} />*/}
          {/*<SidebarSubitem type="gallery-4" click={() => this.onClickSubitem("gallery-4")} />*/}
        </div>
        <div className="rdp-composer-sidebar-overlay" onClick={() => this.closeSubitems()}></div>
      </div>;
    }
    else if (this.state.subitems === "multimedia") {
      subitems = <div>
        <div className="rdp-composer-sidebar-subitems">
          {/* <SidebarSubitem type="video-1" click={() => this.onClickSubitem("video-1")}/> */}
          <SidebarSubitem
            type="audio-1"
            text={this.props.labels.header.elements_descriptions.element_audio}
            click={() => this.onClickSubitem("audio-1")}
          />
          <SidebarSubitem
            type="video-1"
            text={this.props.labels.header.elements_descriptions.element_video}
            click={() => this.onClickSubitem("video-1")}
          />
          <SidebarSubitem
            type="iframe"
            text={this.props.labels.header.elements_descriptions.element_iframe}
            click={() => this.onClickSubitem("iframe")}
          />
          <SidebarSubitem
            type="attachment-1"
            text={this.props.labels.header.elements_descriptions.element_attachment_1}
            click={() => this.onClickSubitem("attachment-1")}
          />
          {/* <SidebarSubitem type="multimedia-4" click={() => this.onClickSubitem("multimedia-4")}/> */}
          {/* <SidebarSubitem type="multimedia-5" click={() => this.onClickSubitem("multimedia-5")}/> */}
        </div>
        <div className="rdp-composer-sidebar-overlay" onClick={() => this.closeSubitems()}></div>
      </div>;
    } else if (this.state.subitems === "interactive") {
      subitems = <div>
        <div className="rdp-composer-sidebar-subitems">
          <SidebarSubitem
            type="accordion"
            text={this.props.labels.header.elements_descriptions.element_accordion}
            click={() => this.onClickSubitem("accordion")}
          />
          <SidebarSubitem
            type="tabs"
            text={this.props.labels.header.elements_descriptions.element_tabs}
            click={() => this.onClickSubitem("tabs")}
          />
          <SidebarSubitem
            type="flashcards-1"
            text={this.props.labels.header.elements_descriptions.element_flashcards}
            click={() => this.onClickSubitem("flashcards-1")}
          />
          <SidebarSubitem
            type="flashcards-2"
            text={this.props.labels.header.elements_descriptions.element_flashcards_2}
            click={() => this.onClickSubitem("flashcards-2")}
          />
          <SidebarSubitem
            type="hotspots-1"
            text={this.props.labels.header.elements_descriptions.element_hotspots_1}
            click={() => this.onClickSubitem("hotspots-1")}
          />
          <SidebarSubitem
            type="sorted-activity-1"
            text={this.props.labels.header.elements_descriptions.sorted_activity_1}
            click={() => this.onClickSubitem("sorted-activity-1")}
          />
          <SidebarSubitem
            type="timeline-1"
            text={this.props.labels.header.elements_descriptions.element_timeline_1}
            click={() => this.onClickSubitem("timeline-1")}
          />
          <SidebarSubitem
            type="steps-1"
            text={this.props.labels.header.elements_descriptions.element_steps_1}
            click={() => this.onClickSubitem("steps-1")}
          />
          <SidebarSubitem
            type="snippet-1"
            text={this.props.labels.header.elements_descriptions.element_snippet_1}
            click={() => this.onClickSubitem("snippet-1")}
          />
          <SidebarSubitem
            type="button-1"
            text={this.props.labels.header.elements_descriptions.element_button_1}
            click={() => this.onClickSubitem("button-1")}
          />
          <SidebarSubitem
            type="button-2"
            text={this.props.labels.header.elements_descriptions.element_button_2}
            click={() => this.onClickSubitem("button-2")}
          />
          <SidebarSubitem
            type="interactive-video"
            text={this.props.labels.header.elements_descriptions.element_interactive_video}
            click={() => this.onClickSubitem("interactive-video")}
          />
          <SidebarSubitem
            type="three-d"
            text={this.props.labels.header.elements_descriptions.element_3d}
            click={() => this.onClickSubitem("3d")}
          />
          {/* <SidebarSubitem type="interactive-3" click={() => this.onClickSubitem("interactive-3")}/>
          <SidebarSubitem type="interactive-4" click={() => this.onClickSubitem("interactive-4")}/>
          <SidebarSubitem type="interactive-5" click={() => this.onClickSubitem("interactive-5")}/>
          <SidebarSubitem type="interactive-6" click={() => this.onClickSubitem("interactive-6")}/>
          <SidebarSubitem type="interactive-7" click={() => this.onClickSubitem("interactive-7")}/>
          <SidebarSubitem type="interactive-9" click={() => this.onClickSubitem("interactive-9")}/>
          <SidebarSubitem type="interactive-10" click={() => this.onClickSubitem("interactive-10")}/>
          <SidebarSubitem type="interactive-11" click={() => this.onClickSubitem("interactive-11")}/> */}
        </div>
        <div className="rdp-composer-sidebar-overlay" onClick={() => this.closeSubitems()}></div>
      </div>;
    } else if (this.state.subitems === "quiz") {
      subitems = <div>
        <div className="rdp-composer-sidebar-subitems">
          <SidebarSubitem text={this.props.labels.header.elements_descriptions.element_quiz_1} type="quiz-1" click={() => this.onClickSubitem("quiz-1")} />
          <SidebarSubitem text={this.props.labels.header.elements_descriptions.element_quiz_2} type="quiz-2" click={() => this.onClickSubitem("quiz-2")} />
          <SidebarSubitem text={this.props.labels.header.elements_descriptions.element_quiz_3} type="quiz-3" click={() => this.onClickSubitem("quiz-3")} />
          <SidebarSubitem text={this.props.labels.header.elements_descriptions.element_quiz_4} type="quiz-4" click={() => this.onClickSubitem("quiz-4")} />
          <SidebarSubitem text={this.props.labels.header.elements_descriptions.element_quiz_5} type="quiz-5" click={() => this.onClickSubitem("quiz-5")} />
          <SidebarSubitem text={this.props.labels.header.elements_descriptions.element_quiz_6} type="quiz-6" click={() => this.onClickSubitem("quiz-6")} />
        </div>
        <div className="rdp-composer-sidebar-overlay" onClick={() => this.closeSubitems()}></div>
      </div>;
    } else if (this.state.subitems === "chart") {
      subitems = <div>
        <div className="rdp-composer-sidebar-subitems">
          <SidebarSubitem type="chart-1" click={() => this.onClickSubitem("chart-1")} />
          <SidebarSubitem type="chart-2" click={() => this.onClickSubitem("chart-2")} />
          <SidebarSubitem type="chart-3" click={() => this.onClickSubitem("chart-3")} />
        </div>
        <div className="rdp-composer-sidebar-overlay" onClick={() => this.closeSubitems()}></div>
      </div>;
    } else if (this.state.subitems === "divider") {
      subitems = <div>
        <div className="rdp-composer-sidebar-subitems">
          <SidebarSubitem type="divider-1" click={() => this.onClickSubitem("divider-1")} />
          <SidebarSubitem type="divider-2" click={() => this.onClickSubitem("divider-2")} />
          <SidebarSubitem type="divider-3" click={() => this.onClickSubitem("divider-3")} />
          <SidebarSubitem type="divider-4" click={() => this.onClickSubitem("divider-4")} />
        </div>
        <div className="rdp-composer-sidebar-overlay" onClick={() => this.closeSubitems()}></div>
      </div>;
    } else if (this.state.subitems === "activities") {
      subitems = <div>
        <div className="rdp-composer-sidebar-subitems">
          <SidebarSubitem text={this.props.labels.header.elements_descriptions.element_forum_1} type="forum-1" click={() => this.onClickSubitem("forum-1")} />
          {/* <SidebarSubitem text={this.props.labels.header.elements_descriptions.element_tex_1} type="tex-1" click={() => this.onClickSubitem("tex-1")} /> */}
        </div>
        <div className="rdp-composer-sidebar-overlay" onClick={() => this.closeSubitems()}></div>
      </div>
    } else if (this.state.subitems === "markers") {
      subitems = <div>
        <div className="rdp-composer-sidebar-subitems">
          <SidebarSubitem text={this.props.labels.header.elements_descriptions.virtual_element_index_1} type="virtual-index-1" click={() => this.onClickSubitem("virtual-index-1")} />
          <SidebarSubitem text={this.props.labels.header.elements_descriptions.element_divider_1} type="divider-1" click={() => this.onClickSubitem("divider-1")} />
          <SidebarSubitem text={this.props.labels.header.elements_descriptions.element_divider_4} type="divider-4" click={() => this.onClickSubitem("divider-4")} />
          <SidebarSubitem text={this.props.labels.header.elements_descriptions.element_divider_numbered} type="divider-numbered" click={() => this.onClickSubitem("divider-numbered")} />
        </div>
        <div className="rdp-composer-sidebar-overlay" onClick={() => this.closeSubitems()}></div>
      </div>
    } else {
      subitems = "";
    }

    return (
      <div className={`rdp-composer-sidebar rdp-composer-sidebar-theme-${this.state.theme}`}>
        {this.props.isEmbed &&
          <span className={`rdp-composer-sidebar-back-embed`} onClick={() => this.props.onBackClick()}>{this.props.labels.header.back_btn}</span>
        }
        {!this.props.isEmbed &&
          <span className="rdp-composer-sidebar-back" onClick={() => this.props.onBackClick()}>{this.props.labels.header.back_btn}</span>
        }
        {/* <span className="rdp-composer-sidebar-logo"></span> */}
        <ul className="rdp-composer-sidebar-items">
          <SidebarBtn label={this.props.labels.header.element_header} icon="header" click={() => this.renderSubitems("header")} />
          <SidebarBtn label={this.props.labels.header.element_text} icon="text" click={() => this.renderSubitems("text")} />
          <SidebarBtn label={this.props.labels.header.element_list} icon="list" click={() => this.renderSubitems("list")} />
          {/* <SidebarBtn label="Declaração" icon="statement" click={() => this.renderSubitems("statement")}/> */}
          {/* <SidebarBtn label="Depoimento" icon="quote" click={() => this.renderSubitems("quote")}/> */}
          {/* <SidebarBtn label="Lista" icon="list" click={() => this.renderSubitems("list")}/> */}
          <SidebarBtn label={this.props.labels.header.element_image} icon="image" click={() => this.renderSubitems("image")} />
          <SidebarBtn label="Galeria" icon="gallery" click={() => this.renderSubitems("gallery")}/>
          <SidebarBtn label={this.props.labels.header.element_multimedia} icon="multimedia" click={() => this.renderSubitems("multimedia")} />
          <SidebarBtn label={this.props.labels.header.element_interactivity} icon="interactive" click={() => this.renderSubitems("interactive")} />
          <SidebarBtn label={this.props.labels.header.element_quiz} icon="quiz" click={() => this.renderSubitems("quiz")} />
          <SidebarBtn label={this.props.labels.header.element_activities} icon="activities" click={() => this.renderSubitems("activities")} />
          <SidebarBtn label={this.props.labels.header.element_markers} icon="markers" click={() => this.renderSubitems("markers")} />
          {/* <SidebarBtn label="Gráfico" icon="chart" click={() => this.renderSubitems("chart")}/> */}
          {/* <SidebarBtn label="Divisor" icon="divider" click={() => this.renderSubitems("divider")}/> */}
        </ul>
        {/* <span className="rdp-composer-sidebar-preview" onClick={() => this.openPreview()}>Preview</span> */}
        {subitems}
        <span className="rdp-composer-sidebar-menu-btn" onClick={() => this.props.onToggleMenu()}></span>

        {this.renderMenu()}
      </div>
    );
  }
}
