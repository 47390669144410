import React from "react";

export const TablePropertiesAction = ({ onSave }) => {
  return (
    <div className="rdp-composer-element-edit-footer">
      <button className="rdp-composer-element-edit-submit" onClick={onSave}>
        Salvar
      </button>
    </div>
  );
};
