import React from "react";

export const IconTrash = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 768 768">
    <title></title>
    <g id="icomoon-ignore"></g>
    <path
      fill="currentColor"
      d="M607.5 127.5v64.5h-447v-64.5h111l33-31.5h159l33 31.5h111zM192 607.5v-384h384v384c0 34.5-30 64.5-64.5 64.5h-255c-34.5 0-64.5-30-64.5-64.5z"
    ></path>
  </svg>
);
